import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import './sideSelectors.pcss'

// Validator does not allow empty aria-label for disabled buttons, hence the
// otherwise pointless "seuraava" and "edellinen" labels on them.

const SideSelectors = ({ options, value, onChange, children }) => {
  const selectedIndex = options.findIndex(opt => opt.label === value)
  const nextOption = options[selectedIndex + 1]
  const prevOption = options[selectedIndex - 1]

  const handleClickPrev = () => {
    onChange(prevOption)
  }

  const handleClickNext = () => {
    onChange(nextOption)
  }

  return (
    <div className='arrow-selectors'>
      <button
        type='button'
        onClick={ handleClickPrev }
        disabled={ prevOption == null }
        className='arrow__selector arrow__selector_left'
        aria-label={ prevOption?.label ?? 'edellinen' }
        title={ prevOption?.label }
      >
        <span className='fa fa-angle-left' aria-hidden='true' />
      </button>
      <div className='arrow-selectors_children'>
        { children }
      </div>
      <button
        type='button'
        onClick={ handleClickNext }
        disabled={ nextOption == null }
        className='arrow__selector arrow__selector_right'
        aria-label={ nextOption?.label ?? 'seuraava' }
        title={ nextOption?.label }
      >
        <span className='fa fa-angle-right' aria-hidden='true' />
      </button>
    </div>
  )
}

SideSelectors.propTypes = exact({
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
})

export default SideSelectors
