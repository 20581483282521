import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import classnames from 'classnames'
import '../../styles/settings.pcss'
import { Helmet } from 'react-helmet'
import TagsInput from 'react-tagsinput'
import Button from '../../components/ui/Button'
import {
  addWordFilter,
  fetchUserProfile,
  removeWordFilter,
} from '../../user/userActions'
import VoteFilter from './VoteFilter'
import {
  selectUserVoteFilterThreshold,
  selectUserWordFilters,
} from '../../selectors/userSelector'

const CHAR_FILTERS = [
  {char: '?', description: '(kysymysmerkki)'},
  {char: '!', description: '(huutomerkki)'},
  {char: '"', description: '(lainausmerkki)'},
  {char: '…', description: '(kolme pistettä)'},
]

@provideHooks({
  fetch: ({ dispatch }) => {
    return Promise.all([
      dispatch(fetchUserProfile(5)),
    ])
  },
})

@connect((state) => {
  return {
    wordFilters: selectUserWordFilters(state),
    voteFilterThreshold: selectUserVoteFilterThreshold(state),
  }
})

export default class WordFiltersView extends Component {
  constructor(props) {
    super(props)

    this.handleTagRemove = this.handleTagRemove.bind(this)

    this.state = {
      inputText: '',
      errorMessage: '',
    }

    this.tags = React.createRef()
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    wordFilters: ImmutablePropTypes.orderedSet.isRequired,
    voteFilterThreshold: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  render() {
    const {
      inputText,
      errorMessage,
    } = this.state

    const {
      wordFilters,
      voteFilterThreshold,
    } = this.props

    // Filter out the single character filters
    const tags = wordFilters.filter(expression => expression.length > 1).toArray()

    return (
      <div>
        <Helmet title='Suodatus' />

        <section className='content'>
          <div className='personal word-filtering'>
            <div className='word-filters'>
              <h3>Uutisten sanasuodatus</h3>
              <p>Voit suodattaa uutisistasi tietyn sanan tai lauseen sisältävät otsikot pois. Lisää yksi sana tai lause kerrallaan.
                <br />
                Suodattimia voi olla enintään 40 kappaletta.
              </p>

              { !!errorMessage &&
                <span className='settings-input-error'>
                  { errorMessage }
                </span>
              }
              <TagsInput
                ref={ this.tags }
                value={ tags }
                currentValue={ inputText }
                onChange={ this.handleChange }
                renderLayout={ this.renderLayout }
                renderInput={ this.renderInput }
                renderTag={ this.renderTag }
                onRemove={ this.handleTagRemove }
                inputProps={ {
                  placeholder: 'Lisää sana tai lause',
                  'aria-label': 'Suodatettava sana tai lause',
                  onSubmit: this.handleSubmit,
                } }
                tagProps={ {
                  className: 'react-tagsinput-tag',
                  classNameRemove: 'react-tagsinput-remove',
                  handleTagRemove: this.handleTagRemove,
                } }
                removeKeys={ [] }
              />
            </div>

            <hr />

            <div className='char-filters'>
              <h3>Uutisten merkkisuodatus</h3>
              <p>Voit suodattaa uutisistasi tietyn merkin sisältävät otsikot.</p>
              <ul>
                { CHAR_FILTERS.map(({char, description}) => {
                  return (
                    <li key={ char }>
                      <label>
                        <input
                          type='checkbox'
                          value={ char }
                          checked={ wordFilters.contains(char) }
                          onChange={ this.handleCharFilterChange }
                        />
                        <span className='char-filter-char'>{ char }</span> { description }
                      </label>
                    </li>
                  )
                }) }
              </ul>
            </div>

            <hr />

            <div className='votes-filter'>
              <h3>Negatiivisia ääniä saaneiden uutisten suodatus</h3>
              <p>Suodata pois mielestäsi liian monta miinusääntä saaneet uutisotsikot.</p>
              <VoteFilter
                dispatch={ this.props.dispatch }
                voteFilterThreshold={ voteFilterThreshold }
              />
            </div>

          </div>
        </section>
      </div>
    )
  }

  handleSubmit = () => {
    this.tags.current.accept()
  }

  handleChange = (tags) => {
    const { wordFilters, dispatch } = this.props

    // handle deleted tags (excluding single char ones)
    wordFilters.filter(expression => expression.length > 1).forEach(expression => {
      if (!tags.includes(expression)) {
        dispatch(removeWordFilter(expression))
      }
    })

    // handle added tags
    tags.forEach(tag => {
      if (!wordFilters.includes(tag)) {
        this.handleAddition(tag)
      }
    })
  }

  handleAddition = (tag) => {
    const str = tag.trim().replace(/\s+/g, ' ')

    if (str.length < 2 || str.match(/^.\*$/)) {
      return this.setError(tag, 'Sanan tulee sisältää vähintään kaksi merkkiä.')
    }
    if (str.length > 84) {
      return this.setError(tag, 'Sanan tai lauseen tulee olla maksimissaan 84 merkkiä.')
    }
    if (str.match(/\*./)) {
      return this.setError(tag, 'Tähtimerkin "*" tulee sijaita sanan lopussa.')
    }
    if (str.includes('*') && str.includes(' ')) {
      return this.setError(tag, 'Tähtimerkin "*" käyttö on rajattu yksittäisiin sanoihin.')
    }
    if (str.split(' ').some(word => word.length < 2) ) {
      return this.setError(tag, 'Kaikkien sanojen tulee sisältää vähintään kaksi merkkiä.')
    }
    const invalidCharacters = str.replace(/[ a-zA-Z0-9À-ÖØ-öø-ÿåÅČĆŽŠĐčćžšđ*-]/g, '')
    if (invalidCharacters.length > 0) {
      return this.setError(tag, 'Sana tai lause voi sisältää vain kirjaimia ja numeroita. Poista merkit:' + invalidCharacters)
    }

    this.setState({
      inputText: '',
      errorMessage: '',
    })

    this.props.dispatch(addWordFilter(str))
  }

  setError(inputText, errorMessage) {
    this.setState({
      inputText,
      errorMessage,
    })
  }

  handleCharFilterChange = (e) => {
    const { dispatch } = this.props
    const char = e.target.value
    if (e.target.checked) {
      dispatch(addWordFilter(char))
    } else {
      dispatch(removeWordFilter(char))
    }
  }

  handleTagRemove(removeFnc, key) {
    return function(e) {
      return removeFnc(key)
    }
  }

  renderLayout(tagComponents, inputComponent) {
    return (
      <div>
        { inputComponent }
        <span className='description'>
          Sanan loppuosan voi korvata *-merkillä. Sanan minimimerkkimäärä on 2 ja sanan tai lauseen maksimimerkkimäärä on 84.
        </span>
        <div className='tags'>{ tagComponents }</div>
      </div>
    )
  }

  renderInput({addTag, ...props}) {
    const { onChange, onSubmit, value, ...other } = props
    return (
      <div className='input-wrapper'>
        <input
          type='text'
          onChange={ onChange }
          value={ value }
          { ...other }
        />
        <Button type='button' text='Lisää' onClick={ onSubmit } />
      </div>
    )
  }

  renderTag(props) {
    const {
      handleTagRemove,
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props

    return (
      <span key={ key } { ...other }>
        { getTagDisplayValue(tag) }
        { !disabled &&
          <button
            type='button'
            className={ classnames('fa fa-times', classNameRemove) }
            onClick={ handleTagRemove(onRemove, key) }
            title='Poista suodatin'
            aria-label='Poista suodatin'
          />
        }
      </span>
    )
  }

}
