import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import { checkTempProfileCreate, favoriteChannel, unfavoriteChannel } from '../../user/userActions'

export default class ChannelFavoriteWidget extends PureComponent {

  static propTypes = exact({
    channelId: PropTypes.number.isRequired,
    channelName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  })

  render() {
    const { active, channelName } = this.props
    const title = active ? `Poista ${channelName} suosikkikanavista` : `Lisää ${channelName} suosikkikanaviin`

    return (
      <button
        type='button'
        className={ classnames('tv-channel-favorite-widget favorite-widget', { active: active }) }
        onClick={ this.handleClick }
        title={ title }
        aria-label={ title }
      >
        { active ? <span className='icon fa fa-star' /> : <span className='icon fa fa-star-o' /> }
      </button>
    )
  }

  handleClick = () => {
    const { dispatch, channelId, active } = this.props

    dispatch(checkTempProfileCreate())
    if (!active) {
      dispatch(favoriteChannel(channelId))
    } else {
      dispatch(unfavoriteChannel(channelId))
    }
  }
}
