import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { connect } from 'react-redux'
import Sticky from 'react-stickynode'
import {
  selectShowUserFeedsSidebar,
  selectUserActiveTheme,
  selectUserSavedSearches,
  selectIsUserLoggedIn,
} from '../../selectors/userSelector'
import { selectTrendingTagItems } from '../../selectors/tagsSelector'
import SocialMediaLinks from '../../components/widgets/SocialMediaLinks'
import { BoxAd, AlmaAjoBoxAd, BOX_AD_RIGHT_1, BOX_AD_RIGHT_2, BOX_AD_RIGHT_3, BOX_AD_RIGHT_4 } from '../../ad/Ad'
import { CooperationBannerBox } from '../../ad/CooperationBanner'
import SidebarPopular from '../../components/widgets/SidebarPopular'
import SidebarUserFeed from '../../components/widgets/SidebarUserFeed'
import TrendingTagsBox from '../widgets/TrendingTagsBox'
import { TelkkuColumn } from '@almamedia/alma-embeds/dist/telkku-column'
import { selectLocationPath } from '../../selectors/statusSelector'
import { siteUrl } from '../../../config/config'
import '../../styles/sidebar.pcss'
import WidgetBox from '../widgets/WidgetBox'
import SavedSearches from '../widgets/SavedSearches'
import TelkkuValioliiga from '../../assets/telkku_valioliiga.png'

/* IMPORTANT:
* When using GeneralSidebar component on a page make sure to include the id='sticky-ad-bottom-boundary'
* property on the pages content div, so that on small height screens the sticky ad does not scroll over the footer
* --Yacine
*/
@connect((state) => {

  const theme = selectUserActiveTheme(state)

  return {
    showUserFeedsBox: selectShowUserFeedsSidebar(state),
    savedSearches: selectUserSavedSearches(state),
    trendingTags: selectTrendingTagItems(state),
    trendingFailedToLoad: state.trending.get('failedToLoad'),
    path: selectLocationPath(state),
    loggedIn: selectIsUserLoggedIn(state),
    isDarkThemeEnabled: theme === 'night' || theme === 'matrix',
  }
})

export default class GeneralSidebar extends Component {

  static propTypes = exact({
    trendingTags: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      whitelisted: PropTypes.bool.isRequired,
      blacklisted: PropTypes.bool.isRequired,
    })).isRequired,
    savedSearches: ImmutablePropTypes.orderedSet.isRequired,
    trendingFailedToLoad: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    showUserFeedsBox: PropTypes.bool.isRequired,
    isDarkThemeEnabled: PropTypes.bool.isRequired,
    category: ImmutablePropTypes.record,
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    tag: ImmutablePropTypes.record,
  })


  render() {

    const {
      trendingTags,
      trendingFailedToLoad,
      savedSearches,
      path,
      showUserFeedsBox,
      isDarkThemeEnabled,
      category,
      dispatch,
      loggedIn,
      tag,
    } = this.props

    return (
      <aside className='sidebar'>

        <TrendingTagsBox
          trending={ trendingTags }
          failedToLoad={ trendingFailedToLoad }
        />

        { path.startsWith('/tv') &&
          <CooperationBannerBox
            title='Yhteistyössä Telkku:' path={ path }
          >
            <TelkkuColumn darkThemeEnabled={ isDarkThemeEnabled } utmSource={ siteUrl } />
          </CooperationBannerBox>
        }

        <BoxAd adUnitId={ BOX_AD_RIGHT_1 } />

        <WidgetBox title='Tallennetut haut'>
          <SavedSearches dispatch={ dispatch } savedSearches={ savedSearches } loggedIn={ loggedIn } />
        </WidgetBox>

        { path === '/valioliiga' && (
          <CooperationBannerBox
            title='Yhteistyössä Telkku:' path={ path }
            wrappingClass='telkku-valioliiga'
          >
            <a href='https://www.iltalehti.fi/telkku/valioliiga' target='_blank' rel='noopener noreferrer'>
              <img src={ TelkkuValioliiga } alt='Valioliiga' />
            </a>
          </CooperationBannerBox> )
        }

        <SidebarPopular category={ category } tag={ tag } />

        { !path.startsWith('/saa') &&
          <BoxAd adUnitId={ BOX_AD_RIGHT_2 } />
        }

        { !path.startsWith('/saa') &&
          <CooperationBannerBox title='Alma Ajon kumppani:' path={ path }>
            <AlmaAjoBoxAd adUnitId={ BOX_AD_RIGHT_3 } />
          </CooperationBannerBox>
        }

        { !path.startsWith('/saa') &&
          <BoxAd adUnitId={ BOX_AD_RIGHT_4 } />
        }

        { showUserFeedsBox &&
          <SidebarUserFeed />
        }

        { !path.startsWith('/saa') && (
          <CooperationBannerBox title='Alma Ajon kumppani:' path={ path }>
            <AlmaAjoBoxAd adUnitId='almad-aside-right-5' />
          </CooperationBannerBox>
        ) }


        <SocialMediaLinks />

        { !path.startsWith('/saa') && (
          <CooperationBannerBox title='Alma Ajon kumppani:' path={ path }>
            <AlmaAjoBoxAd adUnitId='almad-aside-right-6' />
          </CooperationBannerBox>
        ) }

        { path === '/' && (
          <CooperationBannerBox
            src='https://feed.etuovi.com/etuovi/html/eo-2022-300x300-kaupungit_ampparit.html'
            title='Yhteistyössä Etuovi.com:' iframeTitle='Etuovi.com mainos' path={ path }
          />
        )
        }

        <Sticky top={ 50 } bottomBoundary='#sticky-ad-bottom-boundary'>
          { !path.startsWith('/saa') &&
            <BoxAd adUnitId='almad-aside-right-7' />
          }
        </Sticky>
      </aside>
    )
  }
}
