import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import classnames from 'classnames'
import { provideHooks } from 'redial'
import CategoryItem from './CategoryItem'
import '../../styles/settings.pcss'
import { fetchUserProfile } from '../userActions'
import { selectCategoryTree } from '../../selectors/menuSelectors'


@provideHooks({
  fetch: ({ dispatch }) => {
    return Promise.all([
      dispatch(fetchUserProfile(5)),
    ])
  },
})

// Filter from menuItems (etusivu, omat, uusimmat, luetuimmat, aanestetyimmat and all without id) out since they are not categories
@connect((state) => {
  return {
    userCategories: state.user.get('categories'),
    items: selectCategoryTree(state),
  }
})


export default class CategorySettingsView extends Component {
  static propTypes = { // eslint-disable-line react/prefer-exact-props
    items: ImmutablePropTypes.listOf(ImmutablePropTypes.record).isRequired,
    userCategories: ImmutablePropTypes.map.isRequired,
  }

  render() {
    const {
      items,
      userCategories,
    } = this.props

    return (
      <div>
        <Helmet title='Kategoriat' />
        <section className='content'>
          <div className='profile-settings-info-text'>
            <span>Valitse, mitä kategorioita et halua nähdä profiilissasi ja mitä haluat seurata Omat-listallasi.</span>
          </div>
          <div className='personal'>
            <div className='category-settings-container'>
              <CategoryList
                items={ items }
                userCategories={ userCategories }
                isRootList={ true }
              />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export class CategoryList extends Component {

  static propTypes = exact({
    items: ImmutablePropTypes.list.isRequired,
    userCategories: ImmutablePropTypes.map.isRequired,
    isRootList: PropTypes.bool.isRequired,
  })

  render() {
    const {
      items,
      userCategories,
      isRootList,
    } = this.props

    return (
      <ul className={ classnames({'root-category-ul': isRootList}) }>
        { items.map((item) => {
          return (
            <CategoryItem
              key={ item.categoryId }
              item={ item }
              userCategories={ userCategories }
              isRootList={ isRootList }
            />
          )
        }) }
      </ul>
    )
  }
}
