import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect  } from 'react-redux'
import { Link } from 'react-router'
import Loading from '../../components/util/Loading'
import UnableToLoad from '../../components/messages/UnableToLoad'
import TvProgrammingNoResults from './TvProgrammingNoResults'
import { Helmet } from 'react-helmet'
import { ChannelListingTitle } from './ChannelTitle'
import moment from 'moment-timezone'
import ChannelViewProgram from './ChannelViewProgram'
import './channelView.pcss'
import { selectUserActiveTheme } from '../../selectors/userSelector'


@connect(state => {

  const { tv } = state

  return {
    controls: tv.get('controls'),
    failedToLoadPrograms: tv.get('failedToLoadPrograms'),
    loadingPrograms: tv.get('loadingPrograms'),
    theme: selectUserActiveTheme(state),
  }
})

export default class ChannelView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    dispatch: PropTypes.func.isRequired,
    controls: ImmutablePropTypes.map.isRequired,
    failedToLoadPrograms: PropTypes.bool.isRequired,
    loadingPrograms: PropTypes.bool.isRequired,
    channel: ImmutablePropTypes.map.isRequired,
    theme: PropTypes.string.isRequired,
  }

  render() {
    const {
      dispatch,
      controls,
      failedToLoadPrograms,
      loadingPrograms,
      channel,
      theme,
    } = this.props


    const channelName = channel.getIn(['channel', 'name'])
    const channelSlug = channel.getIn(['channel', 'slug'])
    const programs = channel.get('programs')

    const noProgramContent = controls.get('textFilter') !== '' ?
      <TvProgrammingNoResults dispatch={ dispatch } /> : false

    const date = controls.get('date') || undefined
    const backLinkUrl = date ? `/tv?pvm=${date}` : '/tv'
    const spacerSteps = [
      moment(date).startOf('day').add(12, 'hours').valueOf(),
      moment(date).startOf('day').add(17, 'hours').valueOf(),
      moment(date).startOf('day').add(22, 'hours').valueOf(),
      moment(date).startOf('day').add(25, 'hours').valueOf(),
    ]

    return (
      <div className='channel-container'>
        <Helmet>
          <title>{ channelName + ' TV-ohjelmat' }</title>
          <meta name='description' content={ channelName + ' päivän ohjelmat. Ampparit.com TV-opas tarjoaa nopean katsauksen tulevaan TV-tarjontaan.' } />
        </Helmet>

        <Link to={ backLinkUrl }><i className='fa fa-arrow-left' /> Kanavalista</Link>
        <ChannelListingTitle name={ channelName } slug={ channelSlug } date={ date } theme={ theme } />

        { failedToLoadPrograms ?
          <UnableToLoad errorText='Ohjelmistoa ei saatu ladattua.' />
          :
          <Loading loading={ loadingPrograms }>
            <div className='programs'>
              { programs.map((program, i) => {

                const timestamp = program.get('timestamp') * 1000
                let isSpacer = false
                while (spacerSteps.length > 0 && timestamp >= spacerSteps[0]) {
                  isSpacer = true
                  spacerSteps.shift()
                }

                return (
                  <ChannelViewProgram
                    key={ program.get('id') }
                    isSpacer={ isSpacer }
                    program={ program }
                  />
                )
              }) }
              { programs.size === 0 && (noProgramContent ? noProgramContent : (
                <div className='tv-programming-no-results'>Ei ohjelmia</div>
              )) }
            </div>
          </Loading>
        }
      </div>
    )
  }
}
