import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import './mobileMenu.pcss'
import MobileMenuItems from './MobileMenuItems'
import Quickfilter from '../layout/Quickfilter'
import { selectActivePresetProfileId } from '../../selectors/profilesSelector'
import NightModeButton from '../ui/NightModeButton'
import { selectIsUserLoggedIn, selectUserEmail, selectUserSavedSearches } from '../../selectors/userSelector'
import { selectBlacklistedCategoryIds } from '../../selectors/categoriesSelector'
import { selectIsNightModeEnabled } from '../../selectors/userSelector'
import { loginAlmaTunnus, logoutAlmaTunnus } from '../../user/almalogin/almaUserActions'
import { logoutAmpparit } from '../../user/userActions'
import { selectMobMenuLinks } from '../../selectors/menuSelectors'
import { TV, WEATHER, SPORT, TAG, FEEDBACK } from './mobilemenuicons/MobileMenuIcons'
import SavedSearches from '../widgets/SavedSearches'

const menuAdditionalPages = [
  { title: 'TV', to: '/tv', icon: TV, className: 'tv'},
  { title: 'Sää', to: '/saa', icon: WEATHER, className: 'weather' },
  { title: 'Tulokset', to: '/urheilu/tulospalvelu', icon: SPORT, className: 'sport' },
  { title: 'Aiheet', to: '/aihetunnisteet', icon: TAG, className: 'tag' },
  { title: 'Palaute', to: '/palaute', icon: FEEDBACK, className: 'feedback' },
]

const MobileMenu = ({
  menuItems,
  activePresetProfileId,
  loggedIn,
  blacklistedCategoryIds,
  nightMode,
  email,
  dispatch,
  path,
  onLinkClick,
  closeMenu,
  savedSearches,
}) => {
  useEffect(() => {
    // To prevent mobile scroll body if menu is open
    window.document.body.classList.add('overlay-open')

    return () => {
      window.document.body.classList.remove('overlay-open')
    }
  }, [])

  const handleLogin = () => {
    dispatch(loginAlmaTunnus())
  }

  const handleLogout = () => {
    closeMenu() // Close menu before logout. (Otherwise menu stays open after logout for short time)
    dispatch(logoutAmpparit())
    dispatch(logoutAlmaTunnus())
  }

  const handleCloseMenu = useCallback(() => {
    closeMenu()
  }, [closeMenu])

  return (
    <div className='mobile-submenu'>
      <div className='vertical'>
        <section className='menu-section'>
          { loggedIn ? (
            <div className='user-login-info'>

              <div className='user-login-info-text'>
                Olet kirjautuneena Alma-tunnuksella:<br />
                <span className='user-login-info-email'>{ email || 'Kirjataan ulos, sivu päivittyy . . .' }</span>
              </div>
            </div>
          ) : (
            <li className='user-login-info'>
              <button type='button' className='button' onClick={ handleLogin }>
                <span className='button-text'>Kirjaudu</span>
              </button>
            </li>
          ) }
        </section>

        <section className='menu-section'>
          <div className='menu-section-container'>
            { menuAdditionalPages.map((page, i) =>(
              <Link key={ i } to={ page.to } className={ `menu-section-container__item ${page.className}` } onClick={ handleCloseMenu }>
                <span className='menu-section-container__item-icon'>{ page.icon }</span>
                <span className='menu-section-container__item-text'>{ page.title }</span>
              </Link>
            ))
            }
          </div>
        </section>

        <section className='menu-section'>
          <span className='menu-section-title'>Tallennetut haut</span>
          <div className='menu-section-container'>
            <SavedSearches
              dispatch={ dispatch }
              savedSearches={ savedSearches }
              loggedIn={ loggedIn }
              onSavedSearchClick={ handleCloseMenu }
            />
          </div>
        </section>

        <section className='menu-section'>
          <div className='quickfilter-mobile'>
            <Quickfilter
              dispatch={ dispatch }
              loggedIn={ loggedIn }
              activePresetProfileId={ activePresetProfileId }
            />
          </div>
        </section>

        <section className='menu-section'>
          <ul className='mobile-submenu-items'>
            { menuItems.map((page, i) => (
              <MobileMenuItems
                key={ i }
                subKey={ i }
                page={ page }
                path={ path }
                blacklistedCategoryIds={ blacklistedCategoryIds }
                onLinkClick={ onLinkClick }
              />
            )) }
          </ul>
        </section>

        <section className='menu-section'>
          { !loggedIn ? (
            <div className='night-mode-button'>
              <NightModeButton dispatch={ dispatch } nightMode={ nightMode } />
            </div>
          )
            :
            (
              <div className='user-logout'>
                <button type='button' className='button' onClick={ handleLogout }>
                  <span className='button-text'>Kirjaudu ulos</span>
                </button>
              </div>
            ) }
        </section>
      </div>
    </div>
  )
}

MobileMenu.propTypes = exact({
  menuItems: ImmutablePropTypes.listOf(
    ImmutablePropTypes.recordOf({
      to: PropTypes.string.isRequired,
      menuTitle: PropTypes.string.isRequired,
      categoryId: PropTypes.number,
      pages: ImmutablePropTypes.list,
    })
  ).isRequired,
  activePresetProfileId: PropTypes.number,
  loggedIn: PropTypes.bool.isRequired,
  blacklistedCategoryIds: ImmutablePropTypes.set,
  nightMode: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  email: PropTypes.string,
  onLinkClick: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  savedSearches: ImmutablePropTypes.orderedSet,
})

const mapStateToProps = (state) => {
  return {
    menuItems: selectMobMenuLinks(state),
    activePresetProfileId: selectActivePresetProfileId(state),
    loggedIn: selectIsUserLoggedIn(state),
    blacklistedCategoryIds: selectBlacklistedCategoryIds(state),
    nightMode: selectIsNightModeEnabled(state),
    email: selectUserEmail(state),
    savedSearches: selectUserSavedSearches(state),
  }
}

export default connect(mapStateToProps)(MobileMenu)
