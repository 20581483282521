import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import moment from 'moment-timezone'
import { ChannelGridTitle } from './ChannelTitle'
import ProgramItem from './ProgramItem'
import getCurrentProgram from './currentProgram'
import ChannelFavoriteWidget from './ChannelFavoriteWidget'
import ChannelArrows from './ChannelArrows'
import './channel.pcss'

export default class Channel extends Component {

  render() {
    const {
      dispatch,
      channel,
      isFavorite,
      onDetailsActivation,
      today,
      date,
      onRecenterHorizontally,
      noProgramContent,
      userTvFavorites,
      isFavoritesPage,
      channelIndex,
      onClickRight,
      onClickLeft,
      loggedIn,
      theme,
    } = this.props
    const programs = channel.get('programs')
    const channelId = channel.getIn(['channel', 'id'])
    const channelSlug = channel.getIn(['channel', 'slug'])
    const channelName = channel.getIn(['channel', 'name'])

    const currentProgram = today ? getCurrentProgram(programs, moment()) : null

    const spacerSteps = [
      moment(date || undefined).startOf('day').add(12, 'hours').valueOf(),
      moment(date || undefined).startOf('day').add(17, 'hours').valueOf(),
      moment(date || undefined).startOf('day').add(22, 'hours').valueOf(),
      moment(date || undefined).startOf('day').add(25, 'hours').valueOf(),
    ]

    return (
      <div className='channel channel--multicolumn'>
        <div className='channel-wrap'>
          <div className='channel-header'>
            <ChannelGridTitle name={ channelName } slug={ channelSlug } date={ date } theme={ theme } />

            { loggedIn && isFavoritesPage &&
              <ChannelArrows
                channelIndex={ channelIndex }
                userTvFavorites={ userTvFavorites }
                onClickLeft={ onClickLeft }
                onClickRight={ onClickRight }
              />
            }

            <ChannelFavoriteWidget
              dispatch={ dispatch }
              channelId={ channelId }
              active={ isFavorite }
              channelName={ channelName }
            />
          </div>
          <div className='programs'>
            { programs.map((program, i) => {

              const timestamp = program.get('timestamp') * 1000
              let spacer = false
              while (spacerSteps.length > 0 && timestamp >= spacerSteps[0]) {
                spacer = true
                spacerSteps.shift()
              }

              return (
                <ProgramItem
                  key={ program.get('id') }
                  spacer={ spacer }
                  program={ program }
                  current={ currentProgram && currentProgram.get('timestamp') === program.get('timestamp') ? true : false }
                  onDetailsActivation={ onDetailsActivation }
                  onRecenterHorizontally={ onRecenterHorizontally }
                  channelName={ channelName }
                />
              )
            }) }
            { programs.size === 0 && (noProgramContent ? noProgramContent : (
              <div className='tv-programming-no-results'>Ei ohjelmia</div>
            )) }
          </div>
        </div>
      </div>
    )
  }
}

Channel.propTypes = exact({
  dispatch: PropTypes.func.isRequired,
  channel: ImmutablePropTypes.map.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onDetailsActivation: PropTypes.func.isRequired,
  today: PropTypes.bool.isRequired,
  date: PropTypes.string,
  onRecenterHorizontally: PropTypes.func.isRequired,
  noProgramContent: PropTypes.node.isRequired,
  userTvFavorites: ImmutablePropTypes.orderedSet.isRequired,
  isFavoritesPage: PropTypes.bool.isRequired,
  channelIndex: PropTypes.number.isRequired,
  onClickRight: PropTypes.func.isRequired,
  onClickLeft: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
})
