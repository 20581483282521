import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { Helmet } from 'react-helmet'
import Switch from '../../components/ui/Switch'
import { fetchUserProfile } from '../userActions'
import '../../styles/settings.pcss'
import Loading from '../../components/util/Loading'
import { selectBlacklistedTagItems, selectWhitelistedTagItems } from '../../selectors/tagsSelector'
import GenericMessage from '../../components/messages/GenericMessage'


@provideHooks({
  fetch: ({ dispatch }) => {
    return Promise.all([
      dispatch(fetchUserProfile(5)),
    ])
  },
})

@connect((state) => {
  return {
    loading: state.tags.loading,
    whitelistedTagItems: selectWhitelistedTagItems(state),
    blacklistedTagItems: selectBlacklistedTagItems(state),
  }
})

export default class TagSettingsView extends PureComponent {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    loading: PropTypes.bool.isRequired,
    whitelistedTagItems: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
    blacklistedTagItems: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
  }

  render() {
    const { loading, whitelistedTagItems, blacklistedTagItems } = this.props

    return (
      <div>
        <Helmet title='Aihetunnisteet' />

        <section className='content'>
          <div className='profile-settings-info-text'>
            <span>
              Katso, mitkä aiheet olet piilottanut profiilistasi tai mitä aiheita seuraat Omalla listallasi. Voit piilottaa ja tykätä aiheista myös <Link className='text-link' to='/aihetunnisteet'>aihetunnisteet sivulla</Link>.
            </span>
          </div>
          <div className='personal'>
            <div className='sources'>
              <div className='box-header'>
                <span>Seuratut aihetunnisteet</span>
              </div>
              <Loading loading={ loading }>
                { whitelistedTagItems.count() <= 0 &&
                  <GenericMessage type='empty' message='Ei seurattuja aihetunnisteita.' />
                }
                { whitelistedTagItems.map(tag => (
                  <Switch key={ tag.get('id') } tag={ tag } />
                )) }
              </Loading>
            </div>

            <div className='width100'>
              <div className='hidden-sources'>
                <div className='box-header'>Piilotetut aihetunnisteet</div>
                <Loading loading={ loading }>
                  { blacklistedTagItems.count() <= 0 &&
                    <GenericMessage type='empty' message='Ei piilotettuja aihetunnisteita.' />
                  }
                  { blacklistedTagItems.map(tag => (
                    <Switch key={ tag.get('id') } tag={ tag } />
                  )) }
                </Loading>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
