import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import moment from 'moment-timezone'
import enhanceWithClickOutside from 'react-click-outside'
import * as keyCodes from '../../lib/keyCodes'

class ProgramDetails extends Component {
  constructor(props) {
    super(props)

    this.details = React.createRef()
  }


  componentDidMount() {
    const element = this.details.current
    const container = element.parentElement.parentElement
    const centerTop = container.offsetTop

    if (window.document.documentElement.clientWidth < 768) { // horizontally scrolling list
      const centerLeft = container.offsetLeft + element.offsetLeft
      this.props.recenter(centerLeft, centerTop)
    } else { // vertically scrolling grid
      element.style.marginTop = container.offsetHeight - 15 + 'px'
    }

    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('keydown', this.handleKeyPress)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('keydown', this.handleKeyPress)

  }

  handleScroll = (e) => {
    const element = this.details.current

    if (!this.isScrolledIntoView(element)) {
      this.props.close()
    }
  }

  isScrolledIntoView = (el) => {
    const elementTop = el.getBoundingClientRect().top
    const elementBottom = el.getBoundingClientRect().bottom

    return elementTop < window.innerHeight && elementBottom >= 0
  }

  render() {
    const { program, channelName } = this.props
    const time = moment(program.get('timestamp') * 1000)
    const ratingImdb = program.get('ratingImdb')
    const linkImdb = program.get('linkImdb')

    let weekDayAt = time.format('dddd') + 'na '
    weekDayAt = weekDayAt.charAt(0).toUpperCase() + weekDayAt.slice(1)

    return (
      <div className='program-details' ref={ this.details }>
        <div className='program-details__wrap'>
          <div className='program-details__close'>
            <button
              type='button'
              className='fa fa-times'
              aria-hidden='true'
              onClick={ this.handleClose }
            />
          </div>

          <div className='program-details__title'>
            <h4>{ program.get('title') }</h4>
            <p>{ channelName }</p>
            <time className='program-start-time' title={ time.format('dd D.M. H:mm') } dateTime={ time.toISOString() }>
              { weekDayAt + time.format('D.MM.') + ' klo ' + time.format('HH:mm') }
            </time>

            { program.get('movie') && ratingImdb &&
            <div className='program-details__movie-rating'>
              <p>Arvio: { ratingImdb }</p>
              <p>Lue lisää: <a href={ linkImdb } target='_blank' rel='noopener noreferrer'>IMDB</a></p>
            </div>
            }

          </div>

          <div className='program-details__description'>
            <p>{ program.get('description') || 'Ohjelmakuvausta ei saatavilla' }</p>
          </div>
        </div>
      </div>
    )
  }

  handleKeyPress = (e) => {
    if (e.keyCode === keyCodes.esc) this.handleClose()
  }

  handleClose = () => {
    this.props.close()
  }

  /**
   * handleClickOutside() provided by enhanceWithClickOutside HoC
   */
  handleClickOutside(e) {
    if (this.props.onClickOutside) this.props.onClickOutside(e)
  }
}

export default enhanceWithClickOutside(ProgramDetails)

ProgramDetails.propTypes = exact({
  program: ImmutablePropTypes.map.isRequired,
  channelName: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  recenter: PropTypes.func.isRequired,
})
