import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import exact from 'prop-types-exact'
import classnames from 'classnames'
import { selectIsUserLoggedIn, selectUserSavedSearches } from '../../selectors/userSelector'
import SearchInput from './SearchInput'
import { useClickAway } from '../../lib/utils'
import { closeSearch } from './searchActions'
import './searchBar.pcss'

const SearchBar = ({
  searchString,
  resultCount,
  inProgress,
  errorLength,
  isOpen,
  path,
  dispatch,
  loggedIn,
  savedSearches,
}) => {
  const ref = useRef()

  useClickAway(ref, () => {
    if (isOpen) dispatch(closeSearch())
  })

  const handleCloseClick = e => {
    dispatch(closeSearch())
  }

  const isSearchPage = path === '/haku'

  return (
    <div ref={ ref } className={ classnames('search-bar-container', { 'search-page': isSearchPage }) }>
      <div className={ classnames('search-bar', { opened: isOpen, 'search-page': isSearchPage }) }>
        <div className='search-input-container'>
          { isOpen && (
            <SearchInput
              searchString={ searchString }
              resultCount={ resultCount }
              inProgress={ inProgress }
              hasErrors={ errorLength }
              dispatch={ dispatch }
              loggedIn={ loggedIn }
              isSearchPage={ isSearchPage }
              onCloseClick={ handleCloseClick }
              savedSearches={ savedSearches }
            />
          ) }
        </div>
      </div>
    </div>
  )
}

SearchBar.propTypes = exact({
  searchString: PropTypes.string.isRequired,
  resultCount: PropTypes.number.isRequired,
  inProgress: PropTypes.bool.isRequired,
  errorLength: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  savedSearches: ImmutablePropTypes.map,
})

const mapStateToProps = (state) => {
  const { search } = state
  return {
    searchString: search.get('searchString'),
    resultCount: search.get('totalCount'),
    errorLength: search.get('errorLength'),
    inProgress: search.get('inProgress'),
    loggedIn: selectIsUserLoggedIn(state),
    savedSearches: selectUserSavedSearches(state),
  }
}

export default connect(mapStateToProps)(SearchBar)
