import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import TimeSlotFilter from './TimeSlotFilter'
import {
  TV_FILTER_PROGRAM_TIME_COMING,
  TV_FILTER_PROGRAM_TIME_ALL,
  TV_FILTER_PROGRAM_TIME_EVENING,
  TV_FILTER_PROGRAM_TIME_NIGHT,
  TV_FILTER_PROGRAM_TIME_NOW,
} from './filters'

export default class TimeSlotGroup extends Component {
  render() {
    const { activeFilter } = this.props
    return (
      <div className='time-slot-selectors' role='tablist'>
        { this.programOptions.map((slot, i) => {
          return (
            <TimeSlotFilter
              key={ i }
              slot={ slot }
              active={ slot.value === activeFilter }
              onHandleProgramTimeFilterChange={ this.props.onHandleProgramTimeFilterChange }
            />
          )
        }) }
      </div>
    )
  }

  programOptions = [
    {value: TV_FILTER_PROGRAM_TIME_NOW, label: 'Nyt ja Seuraavaksi'},
    {value: TV_FILTER_PROGRAM_TIME_COMING, label: 'Tulevat'},
    {value: TV_FILTER_PROGRAM_TIME_ALL, label: 'Koko päivä'},
    {value: TV_FILTER_PROGRAM_TIME_EVENING, label: 'Ilta (17-22)'},
    {value: TV_FILTER_PROGRAM_TIME_NIGHT, label: 'Yö (22-01)'},
  ]
}

TimeSlotGroup.propTypes = exact({
  activeFilter: PropTypes.string.isRequired,
  onHandleProgramTimeFilterChange: PropTypes.func.isRequired,
})
