import { createSelector } from 'reselect'
import { selectUserBlacklistedTagIds, selectUserWhitelistedTagIds } from './userSelector'
import { tagToTagItem, tagsToTagItems, tagsToCountedTagItems } from '../components/item/tagsTransformers'

/** @return {Immutable.Map<string, TagRecord>} */
const selectTags = state => state.tags.tags

/** @return {Immutable.OrderedSet<TagRecord>} */
const selectTrendingTags = state => state.trending.get('tags')

/** @return {Immutable.OrderedSet<TagRecord>} */
const selectAllTags = state => state.tags.allTags.get('allTagsItems')

/** @return {?TagRecord} */
export const selectTagBySlug = (state, slug) => selectTags(state).get(slug)

/** @type {(state: object, slugs: Immutable.Set<string>) => Immutable.List<Immutable.Map<string,any>>} */
export const selectTagItemsBySlugs = createSelector(
  selectTags,
  state => state.user.get('tags'),
  (state, slugs) => slugs,
  (tagsMap, userTags, slugs) => {
    const tagRecords = slugs.map(slug => tagsMap.get(slug)).filter(tag => tag != null)
    return tagsToTagItems(tagRecords, userTags).toList()
  }
)

/** @type {(state: object) => Immutable.List<Immutable.Map<string,any>>} */
export const selectWhitelistedTagItems = createSelector(
  selectTags,
  selectUserWhitelistedTagIds,
  state => state.user.get('tags'),
  (tagsMap, tagIds, userTags) => {
    const tags = tagsMap.filter(tag => tagIds.includes(tag.id))
    return tagsToTagItems(tags, userTags).toList()
  }
)

/** @type {(state: object) => Immutable.List<Immutable.Map<string,any>>} */
export const selectBlacklistedTagItems = createSelector(
  selectTags,
  selectUserBlacklistedTagIds,
  state => state.user.get('tags'),
  (tagsMap, tagIds, userTags) => {
    const tags = tagsMap.filter(tag => tagIds.includes(tag.id))
    return tagsToTagItems(tags, userTags).toList()
  }
)

/** @return {?Immutable.Map<string,any>} TagItem, null if no breaking news */
export const selectBreakingNewsTagItem = (state) => {
  const tag = state.trending.get('tags').find(tag => tag.get('breaking') === true)
  return tag ? tagToTagItem(tag, state.user.get('tags')) : null
}

/** @type {(state: object) => Immutable.List<Immutable.Map<string,any>>} */
export const selectTrendingTagItems = createSelector(
  selectTrendingTags,
  state => state.user.get('tags'),
  (tags, userTags) => {
    return tagsToTagItems(tags, userTags).toList()
  }
)

/** @type {(state: object) => Immutable.List<Immutable.Map<string,any>>} */
export const selectAllTagsItems = createSelector(
  selectAllTags,
  state => state.user.get('tags'),
  (tags, userTags) => {
    return tagsToCountedTagItems(tags, userTags).toList()
  }
)
