import { combineReducers } from 'redux'
import * as actions from './itemsActions'
import * as popularActions from './popularActions'
import * as userActions from '../../user/userActions'
import * as trendingActions from './trendingActions'
import * as searchActions from '../../views/Search/searchActions'
import * as tagActions from './tagsActions'
import * as breakingNewsActions from '../widgets/breakingNewsActions'
import Immutable from 'immutable'

export const TagRecord = Immutable.Record({
  id: '',
  slug: '',
  h1: '',
  name: '',
  desc: '',
  breaking: false,
}, 'tag')

function tagFromResponse(tag) {
  const { id, slug, h1, name, metaDescription, breaking } = tag

  return new TagRecord({
    id,
    slug,
    h1,
    name,
    desc: metaDescription,
    breaking,
  })
}

function reduceItemsTags(state, items) {
  return items.reduce((acc, item) => {
    return reduceTags(acc, item.tags)
  }, state)
}

function reduceTags(state, tags) {
  return tags.reduce((acc, tag) => {
    return acc.set(tag.slug, tagFromResponse(tag))
  }, state)
}

function loading(state = false, action) {
  switch (action.type) {
    case actions.FETCH_TAG_ITEMS:
    case tagActions.FETCH_ALL_TAGS:
      return true

    case actions.FETCH_TAG_ITEMS_SUCCESS:
    case actions.FETCH_TAG_ITEMS_FAILURE:
    case tagActions.FETCH_ALL_TAGS_SUCCESS:
      return false

    default:
      return state
  }
}

function allTags(state = Immutable.Map(), action) {
  switch (action.type) {
    case tagActions.FETCH_ALL_TAGS:
      return state
        .set('loading', true)
        .set('allTagsItems', Immutable.List())

    case tagActions.FETCH_ALL_TAGS_SUCCESS:
      return state
        .set('loading', false)
        .set('allTagsItems', Immutable.fromJS(action.response.data))

    case tagActions.FETCH_ALL_TAGS_FAILURE:
      return state.set('loading', false)

    default:
      return state
  }
}

function tags(state = Immutable.Map(), action) {
  switch (action.type) {
    case trendingActions.FETCH_TRENDING_SUCCESS:
      return reduceTags(state, action.response.data.tags)

    case userActions.SET_ACTIVE_USER_PROFILE:
      state = reduceTags(state, action.profile.tagWhitelist)
      state = reduceTags(state, action.profile.tagBlacklist)
      return state

    case actions.FETCH_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_ITEMS_SUCCESS:
    case popularActions.FETCH_POPULAR_SUCCESS:
    case actions.FETCH_CUSTOM_FEED_ITEMS_SUCCESS:
    case actions.FETCH_USER_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_USER_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_USER_ITEMS_SUCCESS:
    case actions.FETCH_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_TAG_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_TAG_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_TAG_ITEMS_SUCCESS:
    case breakingNewsActions.FETCH_BREAKING_NEWS_SUCCESS:
      return reduceItemsTags(state, action.response.data)

    case searchActions.FETCH_SEARCH_SUCCESS:
    case searchActions.FETCH_SEARCH_NEXT_SUCCESS:
      return reduceItemsTags(state, action.response.data.results.items)

    case actions.FETCH_MOST_READ_ITEMS_SUCCESS:
    case actions.FETCH_MOST_VOTED_ITEMS_SUCCESS:
      return reduceItemsTags(
        state,
        [].concat.apply([], action.response.data.map(data => data.items)) // flatten
      )

    case userActions.FETCH_RECOMMENDED_CATEGORIES_SUCCESS: {
      const items = action.data.map(content => content.items)
        .reduce((acc, item) => {
          return item
        }, Immutable.Map())

      return reduceItemsTags(
        state,
        items
      )
    }
    case actions.QUEUE_ITEM:
    case actions.QUEUE_TAG_ITEM:
      return reduceTags(state, action.item.tags)

    default:
      return state
  }
}

function timestamp(state = null, action) {
  switch (action.type) {
    case tagActions.FETCH_ALL_TAGS_SUCCESS:
      return action.timestamp

    default:
      return state
  }
}

export default combineReducers({
  loading,
  allTags,
  tags,
  timestamp,
})
