import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'
import { provideHooks } from 'redial'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { Helmet } from 'react-helmet'
import {
  fetchTvPrograms,
  getDefaultChannelFilter,
  setChannelFilter,
  setDateFilter,
  setProgramTimeFilter,
  setProgramTypeFilter,
  setTextFilter,
} from './tvActions'
import { setStatus } from '../../status/statusActions'
import {
  getChannelFilter,
  getProgramTimeFilter,
  getProgramTypeFilter,
  getTextFilter,
  getTVFiltersTodayByProgram,
  translateChannelFilter,
  translateProgramTimeFilter,
  translateProgramTypeFilter,
  TV_FILTER_CHANNEL_ALL,
  TV_FILTER_PROGRAM_TIME_COMING,
  TV_FILTER_PROGRAM_TIME_NOW,
  TV_FILTER_PROGRAM_TIME_ALL,
} from './filters'
import TvControls from './TvControls'
import TelevisionProgramming from './TelevisionProgramming'
import './television.pcss'
import debounce from 'lodash/debounce'
import each from 'lodash/each'
import indexOf from 'lodash/indexOf'
import { selectUserFavoriteTvIds, selectIsUserLoggedIn, selectUserActiveTheme } from '../../selectors/userSelector'
import { setChannelsOrder } from '../../user/userActions'
import SaveTempProfileLink from '../../components/ui/SaveTempProfileLink'
import MainContent from '../../components/wrappers/MainContent'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import { createUrl } from '../../lib/utils'
import shareImg from '../../assets/ampparit-share-tv.jpg'
import { selectCategoryBySlug } from '../../selectors/categoriesSelector'
import { fetchPopular } from '../../components/item/popularActions'


@provideHooks({
  fetch: ({ dispatch, getState, params, query, path }) => {
    const defaultChannelFilter = dispatch(getDefaultChannelFilter())

    const textFilter = query.sanat ? query.sanat : ''
    const channelFilter = translateChannelFilter(query.suodatus, defaultChannelFilter)
    const programTypeFilter = translateProgramTypeFilter(query.tyyppi)
    let programTimeFilter = translateProgramTimeFilter(query.aika)

    // Parse and validate date filter
    const queryDate = moment(query.pvm, 'YYYY-MM-DD')
    const minDate = moment().startOf('day')
    const maxDate = moment().add(11, 'day')
    let dateFilter = null
    if (path === '/tv/huomenna') {
      dateFilter = moment().add(20, 'hours').format('YYYY-MM-DD')
      if (programTimeFilter === TV_FILTER_PROGRAM_TIME_NOW || programTimeFilter === TV_FILTER_PROGRAM_TIME_COMING) {
        programTimeFilter = TV_FILTER_PROGRAM_TIME_ALL
      }
    }
    else if (queryDate.isValid() && queryDate.isBetween(minDate, maxDate, 'day')) {
      dateFilter = queryDate.format('YYYY-MM-DD')
      if (programTimeFilter === TV_FILTER_PROGRAM_TIME_NOW || programTimeFilter === TV_FILTER_PROGRAM_TIME_COMING) {
        programTimeFilter = TV_FILTER_PROGRAM_TIME_ALL
      }
    }
    else if (programTimeFilter === TV_FILTER_PROGRAM_TIME_NOW || programTimeFilter === TV_FILTER_PROGRAM_TIME_COMING) {
      // Omit dateFilter for 'nyt' & 'tulevat', fetches coming programs from API
    }
    else {
      dateFilter = getTVFiltersTodayByProgram(programTimeFilter)
    }

    dispatch(setTextFilter(textFilter))
    dispatch(setChannelFilter(channelFilter))
    dispatch(setProgramTimeFilter(programTimeFilter))
    dispatch(setProgramTypeFilter(programTypeFilter))
    dispatch(setDateFilter(dateFilter))

    const state = getState()
    const channelSlug = params.channel
    const fetchDate = state.tv.getIn(['controls', 'date'])
    const prevFetchDate = state.tv.get('programmingMinDate')

    // Avoid reloading programs when filters change but date stays the same
    if (fetchDate === prevFetchDate) return

    const promises = []

    promises.push(
      dispatch(fetchTvPrograms(fetchDate)).then(() => {
        const singleChannelPage = getState().tv.get('programming').find(p => p.getIn(['channel', 'slug']) === channelSlug)

        // disable channel filtering on single channel page
        if (singleChannelPage && channelFilter !== TV_FILTER_CHANNEL_ALL) {
          dispatch(setChannelFilter(TV_FILTER_CHANNEL_ALL))
        }

        if (channelSlug && !singleChannelPage) {
          return dispatch(setStatus(404))
        }
      })
    )

    promises.push(dispatch(fetchPopular(300, { categoryId: 99 })))
    return Promise.all(promises)
  },
})

@connect((state, ownProps) => {

  const channelSlug = ownProps.params.channel
  const channel = channelSlug ? state.tv.get('programming').find(
    p => p.getIn(['channel', 'slug']) === channelSlug
  ) : null

  return {
    loggedIn: selectIsUserLoggedIn(state),
    channel: channel,
    channelSlug,
    singleChannelPage: !!channel,
    programming: state.tv.get('programming'),
    controls: state.tv.get('controls'),
    userTvFavorites: selectUserFavoriteTvIds(state),
    failedToLoadPrograms: state.tv.get('failedToLoadPrograms'),
    loadingPrograms: state.tv.get('loadingPrograms'),
    theme: selectUserActiveTheme(state),
    category: selectCategoryBySlug(state, 'televisio'),
  }
})

export default class TelevisionView extends Component {
  constructor() {
    super()
    this.state = {
      disabled: false,
    }

    this.onSetTextFilter = debounce(this.onSetTextFilter, 750)

    this.handleClickRight = this.handleClickRight.bind(this)
    this.handleClickLeft = this.handleClickLeft.bind(this)
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    channel: ImmutablePropTypes.map,
    controls: ImmutablePropTypes.map.isRequired,
    failedToLoadPrograms: PropTypes.bool.isRequired,
    loadingPrograms: PropTypes.bool.isRequired,
    children: PropTypes.node,
    singleChannelPage: PropTypes.bool.isRequired,
    userTvFavorites: ImmutablePropTypes.orderedSet.isRequired,
    programming: ImmutablePropTypes.list.isRequired,
    theme: PropTypes.string.isRequired,
    category: ImmutablePropTypes.record,
  }

  filter(programming) {
    const { controls } = this.props

    const textFilter = getTextFilter(controls.get('textFilter'))
    const programTimeFilter = getProgramTimeFilter(controls.get('program'), controls.get('date'), moment())
    const programTypeFilter = getProgramTypeFilter(controls.get('programType'))
    const channelFilter = getChannelFilter(controls.get('channel'))

    let result
    result = channelFilter(programming, this.props.userTvFavorites)
    result = programTypeFilter(result)
    result = programTimeFilter(result)
    result = textFilter(result)

    return result
  }

  render() {
    const {
      dispatch,
      loggedIn,
      channel,
      controls,
      failedToLoadPrograms,
      loadingPrograms,
      children,
      singleChannelPage,
      userTvFavorites,
      programming,
      theme,
      category,
    } = this.props

    const isToday = !controls.get('date') || controls.get('date') === moment().format('YYYY-MM-DD')
    const isTomorrow = !isToday && controls.get('date') === moment().add(1, 'day').format('YYYY-MM-DD')
    const isFavoritesPage = (controls.get('channel') === 'favorites')
    const filteredProgramming = this.filter(programming)

    // Use custom sorting only on favorites page
    let sortedProgramming = filteredProgramming
    if (isFavoritesPage) {
      const orderMap = {}
      each(userTvFavorites.toJS(), (i) => { orderMap[i] = indexOf(userTvFavorites.toJS(), i) })
      sortedProgramming = filteredProgramming.sortBy((obj) => {
        return orderMap[obj.getIn(['channel', 'id'])]
      })
    }

    const description = isToday
      ? 'Ampparit.comin TV-opas tarjoaa nopean katsauksen tulevaan TV-tarjontaan. Palvelusta löydät yli 50 kanavan tv-ohjelmien tiedot. Tarkista kaikkien ilmaiskanavien ja suosituimpien maksukanavien tiedot yhdeltä sivulta!'
      : 'Ampparit.comin TV-opas tarjoaa nopean katsauksen tulevaan TV-tarjontaan. Tarkista kaikki TV-ohjelmat huomenna ja myös tulevien päivien tarjonta. Tarkista kaikkien ilmaiskanavien ja suosituimpien maksukanavien tiedot yhdeltä sivulta!'

    let filteredChannel
    if (singleChannelPage) {
      filteredChannel = filteredProgramming.find(
        p => p.getIn(['channel', 'id']) === channel.getIn(['channel', 'id'])
      )

      // Merge the filtered channel programs to the current channel
      filteredChannel = channel.set('programs', filteredChannel ? filteredChannel.get('programs') : Immutable.Map())
    }

    return (
      <div className='television container' id='sticky-segment-ad-bottom-boundary'>
        <div className='main-container'>
          <MainContent>
            <Helmet
              title='TV-ohjelmat - TV-opas'
              meta={ [
                {name: 'description', content: description},
                {property: 'og:title', content: 'TV-opas \u2013 Ampparit.com'},
                {property: 'og:image', content: createUrl(shareImg)},
                {property: 'og:description', content: 'Ampparit.comin TV-opas tarjoaa nopean katsauksen tulevaan TV-tarjontaan. Tarkista kaikki TV-ohjelmat huomenna ja myös tulevien päivien tarjonta'},
              ] }
            />

            <TvControls
              singleChannelPage={ singleChannelPage }
              controls={ controls }
              setDateFilter={ this.onSetDateFilter }
              setChannelFilter={ this.onSetChannelFilter }
              setProgramTimeFilter={ this.onSetProgramTimeFilter }
              setProgramTypeFilter={ this.onSetProgramTypeFilter }
              setTextFilter={ this.onSetTextFilter }
            />

            { !loggedIn && userTvFavorites && userTvFavorites.size > 0 &&
              <SaveTempProfileLink dispatch={ dispatch }>
                Tallenna suosikkisi <span className='fa fa-star-o' />
              </SaveTempProfileLink>
            }

            { children ?
              singleChannelPage && React.cloneElement(children, {
                disabled: this.state.disabled,
                channel: filteredChannel,
                controls: controls,
                failedToLoadPrograms: failedToLoadPrograms,
                loadingPrograms: loadingPrograms,
                isToday: isToday,
                isTomorrow: isTomorrow,
                onDetailsActivation: this.handleDetailsActivation,
                userTvFavorites: userTvFavorites,
                loggedIn: loggedIn,
                dispatch: dispatch,
              })
              :
              <div className='programming-container'>
                <TelevisionProgramming
                  dispatch={ dispatch }
                  disabled={ this.state.disabled }
                  controls={ controls }
                  failedToLoadPrograms={ failedToLoadPrograms }
                  loadingPrograms={ loadingPrograms }
                  isToday={ isToday }
                  isTomorrow={ isTomorrow }
                  filteredProgramming={ sortedProgramming }
                  onDetailsActivation={ this.handleDetailsActivation }
                  userTvFavorites={ userTvFavorites }
                  isFavoritesPage={ isFavoritesPage }
                  onClickLeft={ this.handleClickLeft }
                  onClickRight={ this.handleClickRight }
                  loggedIn={ loggedIn }
                  theme={ theme }
                />
              </div>
            }

          </MainContent>

          <div className='sidebar-container'>
            <GeneralSidebar category={ category } />
          </div>
        </div>
      </div>
    )
  }

  handleDetailsActivation = (active) => {
    if (this.state.disabled && active) {
      return false
    }

    this.setState({
      disabled: active,
    })

    return true
  }

  handleClickLeft(event) {
    const currentIndex = parseInt(event.currentTarget.value)
    const newIndex = currentIndex - 1
    const newOrder = Immutable.OrderedSet(this.moveArrayItemToNewIndex(this.props.userTvFavorites, currentIndex, newIndex))

    this.props.dispatch(setChannelsOrder(newOrder))
  }

  handleClickRight(event) {
    const currentIndex = parseInt(event.currentTarget.value)
    const newIndex = currentIndex + 1
    const newOrder = Immutable.OrderedSet(this.moveArrayItemToNewIndex(this.props.userTvFavorites, currentIndex, newIndex))

    this.props.dispatch(setChannelsOrder(newOrder))
  }

  moveArrayItemToNewIndex(array, oldIndex, newIndex) {
    const result = [...array]
    result.splice(newIndex, 0, result.splice(oldIndex, 1)[0])
    return result
  }

  onSetDateFilter = (value, updateQueryParams = true) => this.props.dispatch(setDateFilter(value, updateQueryParams))
  onSetChannelFilter = (value, updateQueryParams = true) => this.props.dispatch(setChannelFilter(value, updateQueryParams))
  onSetProgramTimeFilter = (value, updateQueryParams = true) => this.props.dispatch(setProgramTimeFilter(value, updateQueryParams))
  onSetProgramTypeFilter = (value, updateQueryParams = true) => this.props.dispatch(setProgramTypeFilter(value, updateQueryParams))
  onSetTextFilter = (value, updateQueryParams = true) => this.props.dispatch(setTextFilter(value, updateQueryParams))
}
