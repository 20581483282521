import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classNames from 'classnames'


export default class TimeSlotFilter extends Component {

  render() {
    const { slot, active } = this.props

    return (
      <button
        type='button'
        role='tab'
        aria-selected={ active }
        onClick={ this.handleOnClick }
        className={ classNames('time-slot-selector', {'time-slot-selector__active': active}) }
      >
        { slot.label }
      </button>
    )
  }

  handleOnClick = () => {
    this.props.onHandleProgramTimeFilterChange(this.props.slot.value)
  }
}

TimeSlotFilter.propTypes = exact({
  slot: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  active: PropTypes.bool.isRequired,
  onHandleProgramTimeFilterChange: PropTypes.func.isRequired,
})
