import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import moment from 'moment-timezone'
import classNames from 'classnames'
import ProgramDetails from './ProgramDetails'

export default class ProgramItem extends Component {
  constructor() {
    super()
    this.state = {
      active: false,
    }
  }

  render() {
    const {
      program,
      current,
      channelName,
      onRecenterHorizontally,
      spacer,
    } = this.props
    const time = moment(program.get('timestamp') * 1000)

    return (
      <div className={ classNames('program-wrapper', {'item-spacer': spacer} ) }>
        <button
          type='button'
          data-tip
          data-for={ 'program-' + program.get('id') }
          onClick={ this.handleProgramClick }
          className={ classNames('program', {
            'current': current,
            'active': this.state.active,
          }) }
        >
          <time className='program__start-time' title={ time.format('dd D.M. H:mm') } dateTime={ time.toISOString() }>{ time.format('HH:mm') }</time>
          <span className='program__right'>
            <span className='program__title'>
              { program.get('title') }
              { program.get('movie') && <i className='fa fa-film genre' aria-hidden='true' /> }
              { program.get('sport') && <i className='fa fa-futbol-o genre' aria-hidden='true' /> }
            </span>
          </span>
        </button>

        { this.state.active &&
          <div>
            <ProgramDetails
              program={ program }
              channelName={ channelName }
              close={ this.onDescriptionClose }
              onClickOutside={ this.handleDescriptionClose }
              recenter={ onRecenterHorizontally }
            />
          </div>
        }
      </div>
    )
  }

  handleProgramClick = () => {
    if (this.props.onDetailsActivation(!this.state.active)) {
      this.setState({
        active: !this.state.active,
      })
    }
  }

  onDescriptionClose = () => {
    this.setState({
      active: false,
    })
    this.props.onDetailsActivation(false)
  }

  handleDescriptionClose = (e) => {
    e.stopPropagation()
    this.onDescriptionClose()
  }
}

ProgramItem.propTypes = exact({
  program: ImmutablePropTypes.map.isRequired,
  current: PropTypes.bool.isRequired,
  channelName: PropTypes.string.isRequired,
  onRecenterHorizontally: PropTypes.func.isRequired,
  spacer: PropTypes.bool.isRequired,
  onDetailsActivation: PropTypes.func.isRequired,
})
