import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { Helmet } from 'react-helmet'
import Toggle from 'react-toggle'
import classnames from 'classnames'
import {
  fetchUserProfile,
  subscribeUserLanguage,
  unSubscribeUserLanguage,
} from '../userActions'
import '../../styles/settings.pcss'
import { fetchSources } from '../../views/Info/sourcesActions'
import Loading from '../../components/util/Loading'
import SourceItem from './SourceItem'
import UnableToLoad from '../../components/messages/UnableToLoad'
import { selectSourcesOrderedByName } from '../../selectors/sourcesSelector'
import { selectUserBlacklistedSourceIds, selectUserSubscribedLanguageSlugs, selectUserSubscribedSourceIds } from '../../selectors/userSelector'

import Carousel from '../../components/carousel/Carousel'


@provideHooks({
  fetch: ({ dispatch }) => {
    return Promise.all([
      dispatch(fetchSources()),
      dispatch(fetchUserProfile(5)),
    ])
  },
})
@connect((state) => {
  const { sources } = state

  return {
    loading: sources.get('loading'),
    sources: selectSourcesOrderedByName(state),
    blacklistedIds: selectUserBlacklistedSourceIds(state),
    subscribedIds: selectUserSubscribedSourceIds(state),
    failedToLoadSources: sources.get('failedToLoad'),
    subscribedLanguages: selectUserSubscribedLanguageSlugs(state),
  }
})

export default class SourceSettingsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 0,
    }
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    loading: PropTypes.bool.isRequired,
    sources: ImmutablePropTypes.list.isRequired,
    failedToLoadSources: PropTypes.bool.isRequired,
    blacklistedIds: ImmutablePropTypes.set,
    subscribedIds: ImmutablePropTypes.set,
    dispatch: PropTypes.func.isRequired,
    subscribedLanguages: ImmutablePropTypes.set,
  }

  render() {
    const {
      loading,
      sources,
      failedToLoadSources,
      blacklistedIds,
      subscribedIds,
      dispatch,
    } = this.props

    const {
      activeTabIndex,
    } = this.state

    const sourcesTabs = [
      {
        sources: sources.filter(source => source.language === 'fi'),
        title: 'Suomenkieliset lähteet',
        slug: 'fi',
      },
      {
        sources: sources.filter(source => source.language === 'en'),
        title: 'Englanninkieliset lähteet',
        slug: 'en',
      },
      {
        sources: sources.filter(source => source.language === 'sv'),
        title: 'Ruotsinkieliset lähteet',
        slug: 'sv',
      },
    ]

    const tabTitle = sourcesTabs[activeTabIndex]['title']
    const groupedSources = sourcesTabs[activeTabIndex]['sources'].reduce((acc, source) => {
      const char = source.name[0].toUpperCase() // Change to capital if the source name does not begin with it (e.g. "eUrheilu")
      acc[char] ??= []
      acc[char].push(source)
      return acc
    }, {})

    const labels = Object.keys(groupedSources).sort()
    const refs = Object.values(labels).reduce((acc, char) => {
      acc[char] = React.createRef()
      return acc
    }, {})

    return (
      <div>
        <Helmet title='Lähteet' />
        <section className='content'>
          <div className='profile-settings-info-text'>
            <p>
              Valitse, mitkä uutislähteet haluat piilottaa Amppareista.
              Kun piilotat uutislähteen, et näe sen julkaisuja enää millään Amppareiden uutislistalla.
            </p>
            <p>
              Mikäli lähteellä on maksullisia artikkeleita, voit erikseen valita, haluatko nähdä ne uutislistoilla.
              Oletusarvoisesti maksullista sisältöä ei näytetä Amppareissa.
            </p>
            <p>
              Voit seurata ulkomaisista lähteistä tällä hetkellä vain urheilu-uutisia.
              Jos otat ulkomaiset urheilu-uutiset käyttöön, löydät ne Urheilu-kategoriasta ja Uusimmat-listalta.
            </p>
          </div>

          <div className='source-settings__toggle-container'>
            <h3>Salli ulkomaiset lähteet:</h3>

            { sourcesTabs.filter(tab => tab.slug !== 'fi').map(tab => (
              <div className='source-settings__toggle' key={ tab.slug }>
                <label htmlFor={ `lang-toggle-${tab.slug}` }>{ tab.title }</label>
                <Toggle
                  checked={ this.isLanguageSubscribed(tab.slug) }
                  onChange={ this.handleToggleChange }
                  value={ tab.slug }
                  id={ `lang-toggle-${tab.slug}` }
                />
              </div>
            )) }
          </div>

          <div className='personal'>
            <div className='sources'>
              <Carousel
                className='source-settings__tabs'
                onChange={ this.handleCarouselChange }
              >
                { sourcesTabs.map(tab => (
                  <li
                    key={ tab.slug }
                    className={ classnames('source-settings__tabs__list-item', {'disabled': !this.isLanguageSubscribed(tab.slug)}) }
                  >
                    { tab.title }
                  </li>
                )) }
              </Carousel>
              <div className='sources__labels-container'>
                { labels.length >= 10 && this.renderLabels(labels, refs) }
              </div>
              <div className='box-header'>
                <span className='box-header-text'>{ tabTitle }</span>
              </div>
              <Loading loading={ loading }>
                { failedToLoadSources ?
                  <UnableToLoad errorText='Lähdelistan haku ei onnistunut.' />
                  :
                  labels.map(char => (
                    <div className='sources__list-container' key={ char }>
                      <div className='sources__list-label-container'>
                        <span className='sources__list-label' ref={ refs[char] }>{ char }</span>
                      </div>
                      <ul className='sources__list'>
                        { groupedSources[char].map(source => (
                          <li className='list-style' key={ source.id }>
                            <SourceItem
                              source={ source }
                              isBlacklisted={ blacklistedIds.includes(source.id) }
                              isSubscribed={ subscribedIds.includes(source.id) }
                              dispatch={ dispatch }
                            />
                          </li>
                        )) }
                      </ul>
                    </div>
                  ))
                }
              </Loading>
            </div>
          </div>
        </section>
      </div>
    )
  }

  handleToggleChange = (e) => {
    const { dispatch } = this.props

    if (!this.isLanguageSubscribed(e.target.value)) {
      dispatch(subscribeUserLanguage(e.target.value))
    } else {
      dispatch(unSubscribeUserLanguage(e.target.value))
    }
  }

  isLanguageSubscribed(languageSlug) {
    return this.props.subscribedLanguages.includes(languageSlug)
  }

  handleCarouselChange = (index) => {
    this.setState({activeTabIndex: index})
  }

  renderLabels(labels, refs) {
    return (
      <div className='sources__labels__list'>
        { labels.map(char => (
          <button
            type='button'
            className='sources__labels__list-button'
            key={ char }
            onClick={ this.handleLabelClick(refs, char) }
          >
            <span className='sources__labels__list-button-text'>{ char }</span>
          </button>
        ))
        }
      </div>
    )
  }

  handleLabelClick = (refs, key) => () => {
    const element = refs[key].current
    const labelsOffset = 160
    const offsetPosition = element.getBoundingClientRect().top + window.pageYOffset - labelsOffset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }
}
