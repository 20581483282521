import { push } from 'react-router-redux'
import moment from 'moment-timezone'
import { getTvPrograms } from '../../api/api'
import { buildPathWithQuery } from '../../lib/utils'
import {
  TV_FILTER_CHANNEL_DEFAULT,
  TV_FILTER_CHANNEL_FAVORITES,
  untranslateChannelFilter,
  untranslateProgramTimeFilter,
  untranslateProgramTypeFilter,
} from './filters'
import { logException } from '../../sentry'
import { selectUserFavoriteTvIds } from '../../selectors/userSelector'

export const FETCH_TV_PROGRAMS = 'FETCH_TV_PROGRAMS'
export const FETCH_TV_PROGRAMS_SUCCESS = 'FETCH_TV_PROGRAMS_SUCCESS'
export const FETCH_TV_PROGRAMS_FAILURE = 'FETCH_TV_PROGRAMS_FAILURE'

export const SET_CONTROL_DATE = 'SET_CONTROL_DATE'
export const SET_CONTROL_CHANNEL = 'SET_CONTROL_CHANNEL'
export const SET_CONTROL_PROGRAM = 'SET_CONTROL_PROGRAM'
export const SET_CONTROL_PROGRAM_TYPE = 'SET_CONTROL_PROGRAM_TYPE'
export const SET_CONTROL_TEXT_FILTER = 'SET_CONTROL_TEXT_FILTER'

export const RESET_CONTROL_FILTERS = 'RESET_CONTROL_FILTERS'

export function fetchTvPrograms(minDate) {
  const params = {}

  if (minDate) { // Our "TV day" is 28 hours long, lasting from 00:00 until 04:00 next day
    params.min_timestamp = moment(minDate).unix() - 1 // API bug: min_timestamp is not inclusive so subtract 1 sec
    params.max_timestamp = moment(minDate).add(28, 'hour').unix()
  }

  return dispatch => {
    dispatch({
      type: FETCH_TV_PROGRAMS,
    })

    return getTvPrograms(params).then((data) => {
      dispatch({
        type: FETCH_TV_PROGRAMS_SUCCESS,
        data,
        minDate,
        timestamp: Date.now(),
      })
    }).catch(error => {
      logException(error)
      dispatch({
        type: FETCH_TV_PROGRAMS_FAILURE,
        timestamp: Date.now(),
        error,
      })
    })
  }
}

export function updateTVFilterUrlQuery() {
  return (dispatch, getState) => {
    const state = getState()
    const location = state.routing.locationBeforeTransitions

    const textFilter = state.tv.getIn(['controls', 'textFilter'])
    const program = state.tv.getIn(['controls', 'program'])
    const programType = state.tv.getIn(['controls', 'programType'])
    const channel = state.tv.getIn(['controls', 'channel'])
    const date = state.tv.getIn(['controls', 'date'])

    const query = Object.assign({}, location.query, {
      aika: untranslateProgramTimeFilter(program),
      pvm: date,
      sanat: textFilter,
      suodatus: untranslateChannelFilter(channel),
      tyyppi: untranslateProgramTypeFilter(programType),
    })

    let pathname = location.pathname
    if (pathname === '/tv/huomenna') {
      pathname = '/tv'
    }

    return dispatch(
      push(
        buildPathWithQuery(pathname, query)
      )
    )
  }
}

/**
 * Change the date filter.
 * @param value
 * @param updateQueryParams
 * This is optional, as updateQueryParams causes provideHooks to trigger.
 * When modifying multiple filters at once, it's preferred to trigger query update only once via. last change.
 * @returns {function(*)}
 */
export function setDateFilter(value, updateQueryParams = false) {
  return (dispatch) => {
    dispatch({
      type: SET_CONTROL_DATE,
      data: value,
    })

    if (updateQueryParams) {
      dispatch(updateTVFilterUrlQuery())
    }

    return Promise.resolve()
  }
}

/**
 * Change the channel filter.
 * @param value
 * @param updateQueryParams
 * This is optional, as updateQueryParams causes provideHooks to trigger.
 * When modifying multiple filters at once, it's preferred to trigger query update only once via. last change.
 * @returns {function(*)}
 */
export function setChannelFilter(value, updateQueryParams = false) {
  return (dispatch) => {
    dispatch({
      type: SET_CONTROL_CHANNEL,
      data: value,
    })

    if (updateQueryParams) {
      dispatch(updateTVFilterUrlQuery())
    }

    return Promise.resolve()
  }
}

/**
 * Change the program filter.
 * @param value
 * @param updateQueryParams
 * This is optional, as updateQueryParams causes provideHooks to trigger.
 * When modifying multiple filters at once, it's preferred to trigger query update only once via. last change.
 * @returns {function(*)}
 */
export function setProgramTimeFilter(value, updateQueryParams = false) {
  return (dispatch) => {
    dispatch({
      type: SET_CONTROL_PROGRAM,
      data: value,
    })

    if (updateQueryParams) {
      dispatch(updateTVFilterUrlQuery())
    }

    return Promise.resolve()
  }
}

/**
 * Change the program type filter.
 * @param value
 * @param updateQueryParams
 * This is optional, as updateQueryParams causes provideHooks to trigger.
 * When modifying multiple filters at once, it's preferred to trigger query update only once via. last change.
 * @returns {function(*)}
 */
export function setProgramTypeFilter(value, updateQueryParams = false) {
  return (dispatch) => {
    dispatch({
      type: SET_CONTROL_PROGRAM_TYPE,
      data: value,
    })

    if (updateQueryParams) {
      dispatch(updateTVFilterUrlQuery())
    }

    return Promise.resolve()
  }
}

/**
 * Change the program type filter.
 * @param value
 * @param updateQueryParams
 * This is optional, as updateQueryParams causes provideHooks to trigger.
 * When modifying multiple filters at once, it's preferred to trigger query update only once via. last change.
 * @returns {function(*)}
 */
export function setTextFilter(value, updateQueryParams = false) {
  return (dispatch) => {
    dispatch({
      type: SET_CONTROL_TEXT_FILTER,
      data: value,
    })

    if (updateQueryParams) {
      dispatch(updateTVFilterUrlQuery())
    }

    return Promise.resolve()
  }
}

export function resetFilters(updateQueryParams = false) {
  return (dispatch) => {
    dispatch({
      type: RESET_CONTROL_FILTERS,
      channelFilter: dispatch(getDefaultChannelFilter()),
    })

    if (updateQueryParams) {
      dispatch(updateTVFilterUrlQuery())
    }

    return Promise.resolve()
  }
}

export function getDefaultChannelFilter() {
  return (dispatch, getState) => {
    const favoriteChannels = selectUserFavoriteTvIds(getState())

    // display favorites as default channel filter in case user has set at least one favorite
    return favoriteChannels && favoriteChannels.size > 0 ? TV_FILTER_CHANNEL_FAVORITES : TV_FILTER_CHANNEL_DEFAULT
  }
}
