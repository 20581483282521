import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import moment from 'moment-timezone'
import './channelTitle.pcss'

// Make sure the import variable name matches channel slug, but with dashes replaced by underscores.
// This way the variable name will be automagically matched to respective channel.
// File names do not have to match slugs, but why not do it anyway..?
// Most logos sourced from https://logos.fandom.com/wiki/Category:Television_channels_in_Finland
import animal_planet from '../../assets/tv/animal-planet.svg'
import animal_planet_dark from '../../assets/tv/animal-planet-dark.svg'
import ava from '../../assets/tv/ava.svg'
import mtv_juniori from '../../assets/tv/mtv-juniori.svg'
import mtv_juniori_dark from '../../assets/tv/mtv-juniori-dark.svg'
import mtv_max from '../../assets/tv/mtv-max.svg'
import mtv_max_dark from '../../assets/tv/mtv-max-dark.svg'
import mtv_urheilu_1 from '../../assets/tv/mtv-urheilu-1.svg'
import mtv_urheilu_1_dark from '../../assets/tv/mtv-urheilu-1-dark.svg'
import mtv_urheilu_2 from '../../assets/tv/mtv-urheilu-2.svg'
import mtv_urheilu_2_dark from '../../assets/tv/mtv-urheilu-2-dark.svg'
import discovery_channel from '../../assets/tv/discovery-channel.svg'
import discovery_channel_dark from '../../assets/tv/discovery-channel-dark.svg'
import discovery_science from '../../assets/tv/discovery-science.svg'
import eurosport_1 from '../../assets/tv/eurosport-1.svg'
import eurosport_1_dark from '../../assets/tv/eurosport-1-dark.svg'
import eurosport_2 from '../../assets/tv/eurosport-2.svg'
import eurosport_2_dark from '../../assets/tv/eurosport-2-dark.svg'
import frii from '../../assets/tv/frii.svg'
import hero from '../../assets/tv/hero.svg'
import investigation_discovery from '../../assets/tv/investigation-discovery.svg'
import jim from '../../assets/tv/jim.svg'
import kutonen from '../../assets/tv/kutonen.svg'
import liv from '../../assets/tv/liv.svg'
import mtv3 from '../../assets/tv/mtv3.svg'
import national_geographic from '../../assets/tv/national-geographic.svg'
import national_geographic_dark from '../../assets/tv/national-geographic-dark.svg'
import nelonen from '../../assets/tv/nelonen.svg'
import star_channel from '../../assets/tv/star-channel.svg'
import star_channel_dark from '../../assets/tv/star-channel-dark.svg'
import sub from '../../assets/tv/sub.svg'
import tlc from '../../assets/tv/tlc.svg'
import tv5 from '../../assets/tv/tv5.svg'
import tv7 from '../../assets/tv/tv7.svg'
import tv7_dark from '../../assets/tv/tv7-dark.svg'
import v_film_action from '../../assets/tv/v-film-action.svg'
import v_film_action_dark from '../../assets/tv/v-film-action-dark.svg'
import v_film_family from '../../assets/tv/v-film-family.svg'
import v_film_family_dark from '../../assets/tv/v-film-family-dark.svg'
import v_film_hits from '../../assets/tv/v-film-hits.svg'
import v_film_hits_dark from '../../assets/tv/v-film-hits-dark.svg'
import v_film_premiere from '../../assets/tv/v-film-premiere.svg'
import v_film_premiere_dark from '../../assets/tv/v-film-premiere-dark.svg'
import v_sport1_suomi from '../../assets/tv/v-sport1-suomi.svg'
import v_sport1_suomi_dark from '../../assets/tv/v-sport1-suomi-dark.svg'
import v_sport2_suomi from '../../assets/tv/v-sport2-suomi.svg'
import v_sport2_suomi_dark from '../../assets/tv/v-sport2-suomi-dark.svg'
import v_sport_1 from '../../assets/tv/v-sport-1.svg'
import v_sport_1_dark from '../../assets/tv/v-sport-1-dark.svg'
import v_sport_football from '../../assets/tv/v-sport-football.svg'
import v_sport_football_dark from '../../assets/tv/v-sport-football-dark.svg'
import v_sport_golf from '../../assets/tv/v-sport-golf.svg'
import v_sport_golf_dark from '../../assets/tv/v-sport-golf-dark.svg'
import v_sport_plus_suomi from '../../assets/tv/v-sport-plus-suomi.svg'
import v_sport_plus_suomi_dark from '../../assets/tv/v-sport-plus-suomi-dark.svg'
import v_sport_premium from '../../assets/tv/v-sport-premium.svg'
import v_sport_premium_dark from '../../assets/tv/v-sport-premium-dark.svg'
import v_sport_ultra_hd from '../../assets/tv/v-sport-ultra-hd.svg'
import v_sport_ultra_hd_dark from '../../assets/tv/v-sport-ultra-hd-dark.svg'
import v_sport_vinter from '../../assets/tv/v-sport-vinter.svg'
import v_sport_vinter_dark from '../../assets/tv/v-sport-vinter-dark.svg'
import viasat_explore from '../../assets/tv/viasat-explore.svg'
import viasat_history from '../../assets/tv/viasat-history.svg'
import viasat_nature from '../../assets/tv/viasat-nature.svg'
import yle_teema_fem from '../../assets/tv/yle-teema-fem.svg'
import yle_teema_fem_dark from '../../assets/tv/yle-teema-fem-dark.svg'
import yle_tv1 from '../../assets/tv/yle-tv1.svg'
import yle_tv2 from '../../assets/tv/yle-tv2.svg'

const logos = {
  animal_planet, animal_planet_dark,
  ava,
  mtv_juniori, mtv_juniori_dark,
  mtv_max, mtv_max_dark,
  mtv_urheilu_1, mtv_urheilu_1_dark,
  mtv_urheilu_2, mtv_urheilu_2_dark,
  discovery_channel, discovery_channel_dark,
  discovery_science,
  eurosport_1, eurosport_1_dark,
  eurosport_2, eurosport_2_dark,
  frii,
  hero,
  investigation_discovery,
  jim,
  kutonen,
  liv,
  mtv3,
  national_geographic, national_geographic_dark,
  nelonen,
  star_channel, star_channel_dark,
  sub,
  tlc,
  tv5,
  tv7, tv7_dark,
  v_film_action, v_film_action_dark,
  v_film_family, v_film_family_dark,
  v_film_hits, v_film_hits_dark,
  v_film_premiere, v_film_premiere_dark,
  v_sport1_suomi, v_sport1_suomi_dark,
  v_sport2_suomi, v_sport2_suomi_dark,
  v_sport_1, v_sport_1_dark,
  v_sport_football, v_sport_football_dark,
  v_sport_golf, v_sport_golf_dark,
  v_sport_plus_suomi, v_sport_plus_suomi_dark,
  v_sport_premium, v_sport_premium_dark,
  v_sport_ultra_hd, v_sport_ultra_hd_dark,
  v_sport_vinter, v_sport_vinter_dark,
  viasat_explore,
  viasat_history,
  viasat_nature,
  yle_teema_fem, yle_teema_fem_dark,
  yle_tv1,
  yle_tv2,
}



/**
 * We have separate logos for light and dark themes. In theory it should be possible to use
 * a single SVG both and change the fill color with CSS, but in practice it doesn't work.
 * Many logos contain gradients, and Safari & Chrome fail to render them when SVG is linked
 * with <use>. https://bugs.chromium.org/p/chromium/issues/detail?id=751733
 */
function resolveLogoUrl(slug, theme) {
  const slugLight = slug.replace(/-/g, '_')
  const slugDark = slug.replace(/-/g, '_') + '_dark'
  if (theme === 'night' || theme === 'matrix') {
    return logos[slugDark] ?? logos[slugLight]
  }
  return logos[slugLight]
}



/**
 * Shows *either* logo or channel name.
 */
export class ChannelGridTitle extends PureComponent {
  render() {
    const { name, slug, date, theme } = this.props
    const logoUrl = resolveLogoUrl(slug, theme)
    const linkUrl = `/tv/${slug}` + ( date ? `?pvm=${ date }` : '')

    return (
      <div className='channel-title channel-title--grid'>
        <Link to={ linkUrl } >
          { logoUrl &&
            <img className='channel-title__logo' src={ logoUrl } alt={ name } title={ name } />
          }
          { !logoUrl &&
            <h3 className='channel-title__name'>{ name }</h3>
          }
        </Link>
      </div>
    )
  }
}

ChannelGridTitle.propTypes = exact({
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string,
  theme: PropTypes.string.isRequired,
})


/**
 * Shows *all* logo, channel name, and date.
 * \u00A0 = non breaking space, keeps date intact if need to wrap
 */
export class ChannelListingTitle extends PureComponent {
  render() {
    const { name, slug, date, theme } = this.props
    const logoUrl = resolveLogoUrl(slug, theme)

    return (
      <div className='channel-title channel-title--listing'>
        { logoUrl &&
          <img className='channel-title__logo' src={ logoUrl } alt={ name } />
        }
        <h1 className='channel-title__name'>
          { name }{ date && moment(date).format(', dddd\u00A0D.M.') }
        </h1>
      </div>
    )
  }
}

ChannelListingTitle.propTypes = exact({
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string,
  theme: PropTypes.string.isRequired,
})

