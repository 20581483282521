import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router'
import { removeSavedSearch } from '../../user/userActions'
import { loginAlmaTunnus } from '../../user/almalogin/almaUserActions'
import './savedSearches.pcss'

const SavedSearches = ({ savedSearches, loggedIn, dispatch, onSavedSearchClick }) => {

  const handleRemoveSearch = useCallback((e) => {
    const searchTerm = e.currentTarget.dataset.searchterm
    dispatch(removeSavedSearch(searchTerm))
  }, [dispatch])

  const handleLoginRedirect = useCallback(() => {
    dispatch(loginAlmaTunnus())
  }, [dispatch])

  return (
    <div className='saved-search'>
      { savedSearches.size > 0 ? savedSearches.map(searchTerm => (
        <span className='saved-search__item' key={ searchTerm }>
          <Link to={ `/haku?q=${searchTerm}` } className='saved-search__item-link' onClick={ onSavedSearchClick }>{ searchTerm }</Link>
          <button
            className='saved-search__item-remove fa fa-times'
            onClick={ handleRemoveSearch }
            data-searchterm={ searchTerm }
            type='button'
          />
        </span>
      )) : (loggedIn ? <span className='saved-search__empty' title='Voit tallentaa hakuja painamalla tähteä hakukentässä'>Ei tallennettuja hakuja</span> : null)
      }

      { !loggedIn &&
        <button className='saved-search__not-logged-in' onClick={ handleLoginRedirect } type='button'>
          Voit tallentaa hakuja kirjautumalla sisään ja painamalla tähteä hakukentässä
        </button> }
    </div>
  )
}

SavedSearches.propTypes = exact({
  savedSearches: ImmutablePropTypes.orderedSet,
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  onSavedSearchClick: PropTypes.func,
})

export default SavedSearches
