import React, { PureComponent } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'

export default class ChannelViewProgram extends PureComponent {
  static propTypes = exact({
    program: ImmutablePropTypes.map.isRequired,
    isSpacer: PropTypes.bool.isRequired,
  })

  render() {
    const { program, isSpacer } = this.props

    const time = moment(program.get('timestamp') * 1000)
    const imdbRating = program.get('ratingImdb')

    return (
      <div className={ classNames('program', {'program--spacer': isSpacer}) }>
        <time className='program__start-time' title={ time.format('dd D.M. HH:mm') } dateTime={ time.toISOString() }>
          { time.format('HH:mm') }
        </time>
        <div className='program__text'>
          <div className='program__title'>
            { program.get('title') }
            { program.get('sport') && <i className='program__icon fa fa-futbol-o genre' aria-hidden='true' /> }
            { program.get('movie') && <i className='program__icon fa fa-film genre' aria-hidden='true' /> }
            { imdbRating &&
              <a href={ program.get('linkImdb') } target='_blank' rel='noopener noreferrer' className='program__imdb'>
                { `IMDb\u00A0${imdbRating}` }
              </a>
            }
          </div>
          <div className='program__description'>
            { program.get('description') }
          </div>
        </div>
      </div>
    )
  }
}
