import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { fetchSources } from './sourcesActions'
import Loading from '../../components/util/Loading'
import MainContent from '../../components/wrappers/MainContent'
import { selectSourcesOrderedByName } from '../../selectors/sourcesSelector'
import './infoViews.pcss'

@provideHooks({
  fetch: ({ dispatch }) => {
    return dispatch(fetchSources())
  },
})

@connect((state) => {
  return {
    loading: state.sources.get('loading'),
    sources: selectSourcesOrderedByName(state),
  }
})

export default class SourcesView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    loading: PropTypes.bool.isRequired,
    sources: ImmutablePropTypes.list.isRequired,
  }

  render() {
    const { loading, sources } = this.props

    return (
      <MainContent>
        <article className='info-page'>
          <Helmet title='Lähteet' />

          <h1>Kaikki lähteet ({ sources.size }&nbsp;kpl)</h1>

          <Loading loading={ loading || sources.size === 0 }>

            <div className='sources-list'>
              { sources.map(source => (
                <div key={ source.id }><a href={ source.link } rel='noopener noreferrer'>{ source.name }</a></div>
              )) }
            </div>
          </Loading>
        </article>
      </MainContent>
    )
  }
}
