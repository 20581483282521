import Immutable from 'immutable'
import * as actions from './itemsActions'
import * as searchActions from '../../views/Search/searchActions'
import * as popularActions from './popularActions'
import * as userActions from '../../user/userActions'
import * as breakingNewsActions from '../widgets/breakingNewsActions'
import { combineReducers } from 'redux'
import { listQueueLimit, listSize } from '../../lib/items'


const initialItemsState = Immutable.Map({
  itemIds: Immutable.OrderedSet(),
  itemQueueIds: Immutable.OrderedSet(),
  itemsQueuedTotal: 0,
  batches: Immutable.Map(),
  loading: false,
  failedToLoad: false,
  loadingNext: false,
  listEnd: false,
  relatedTags: Immutable.OrderedSet(),
  shouldRefresh: false,
})

const listsInitialState = Immutable.fromJS({
  'featured': initialItemsState,
  'newest': initialItemsState,
  'personal': initialItemsState,
  'paywalled': initialItemsState,
})

function lists(state = listsInitialState, action) {
  switch (action.type) {
    case actions.FETCH_ITEMS:
    case actions.FETCH_ITEMS_SUCCESS:
    case actions.FETCH_ITEMS_FAILURE:
    case actions.FETCH_NEXT_ITEMS:
    case actions.FETCH_NEXT_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_ITEMS_FAILURE:
    case actions.FETCH_BATCH_ITEMS:
    case actions.FETCH_BATCH_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_ITEMS_FAILURE:
    case actions.QUEUE_ITEM:
    case actions.UNQUEUE_ITEMS:
      return state.set(action.list, itemState(state.get(action.list), action))

    case userActions.SET_ACTIVE_USER_PROFILE:
    case userActions.SET_ACTIVE_PRESET_PROFILE:
    case actions.SET_ITEMS_LANGUAGE:
      return state.map(list => {
        return list.set('shouldRefresh', true)
      })

    default:
      return state
  }
}

function shouldInitializeListSegments(state = false, action) {
  switch (action.type) {
    case actions.REFRESH_SEGMENT_LIST:
      return action.refresh

    case actions.FETCH_ITEMS:
    case actions.FETCH_CATEGORY_ITEMS:
    case actions.FETCH_TAG_ITEMS:
    case actions.FETCH_USER_ITEMS:
    case actions.FETCH_MOST_READ_ITEMS:
    case actions.FETCH_MOST_VOTED_ITEMS:
      return true

    default:
      return state
  }
}

const initStateActiveList = Immutable.Map({
  listType: actions.LIST_TYPE_NONE,
  listName: actions.LIST_NAME_NONE,
})
function activeList(state = initStateActiveList, action) {
  switch (action.type) {
    case actions.ACTIVE_LIST:
      return state
        .set('listType', action.listType)
        .set('listName', action.listName)

    default:
      return state
  }
}

function categories(state = Immutable.Map(), action) {
  switch (action.type) {
    case actions.FETCH_CATEGORY_ITEMS:
    case actions.FETCH_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_CATEGORY_ITEMS_FAILURE:
    case actions.FETCH_NEXT_CATEGORY_ITEMS:
    case actions.FETCH_NEXT_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_CATEGORY_ITEMS_FAILURE:
    case actions.QUEUE_CATEGORY_ITEM:
    case actions.UNQUEUE_CATEGORY_ITEMS:
    case actions.FETCH_BATCH_CATEGORY_ITEMS:
    case actions.FETCH_BATCH_CATEGORY_ITEMS_FAILURE:
    case actions.FETCH_BATCH_CATEGORY_ITEMS_SUCCESS:
      return state.set(action.category, itemState(state.get(action.category), action))

    case actions.SET_ITEMS_LANGUAGE:
      return state.map(list => {
        return list.set('shouldRefresh', true)
      })

    default:
      return state
  }
}


function tags(state = Immutable.Map(), action) {
  switch (action.type) {
    case actions.FETCH_TAG_ITEMS:
    case actions.FETCH_TAG_ITEMS_SUCCESS:
    case actions.FETCH_TAG_ITEMS_FAILURE:
    case actions.FETCH_NEXT_TAG_ITEMS:
    case actions.FETCH_NEXT_TAG_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_TAG_ITEMS_FAILURE:
    case actions.QUEUE_TAG_ITEM:
    case actions.UNQUEUE_TAG_ITEMS:
    case actions.FETCH_BATCH_TAG_ITEMS:
    case actions.FETCH_BATCH_TAG_ITEMS_FAILURE:
    case actions.FETCH_BATCH_TAG_ITEMS_SUCCESS:
      return state.set(action.tag, itemState(state.get(action.tag), action))

    default:
      return state
  }
}

export const ItemRecord = Immutable.Record({
  id: '',
  title: '',
  link: '',
  sourceId: null,
  timestamp: 0,
  source: '',
  plus_votes: 0,
  minus_votes: 0,
  currentVote: actions.ITEM_VOTE_TYPE_NEUTRAL,
  currentReport: actions.ITEM_REPORT_TYPE_NEUTRAL,
  clicks: 0,
  categoryId: 0,
  tags: Immutable.OrderedSet(),
  batchId: 0,
  custom: false,
  paywalled: false,
  language: 'fi',
}, 'item')

function itemFromResponse(item) {
  return new ItemRecord({
    id: item.id,
    title: item.title,
    link: item.link,
    sourceId: item.sourceId,
    timestamp: item.timestamp,
    source: item.source,
    plus_votes: item.plus_votes,
    minus_votes: item.minus_votes,
    currentVote: item.currentVote || actions.ITEM_VOTE_TYPE_NEUTRAL,
    currentReport: item.currentReport || actions.ITEM_REPORT_TYPE_NEUTRAL,
    clicks: item.clicks,
    categoryId: item.categoryId,
    tags: Immutable.OrderedSet(item.tags.map(tag => tag.slug)),
    batchId: item.batch.id,
    custom: item.isCustomItem,
    paywalled: item.isPaywalled,
    language: item.language,
  })
}

function items(state = Immutable.Map(), action) {
  let item
  switch (action.type) {
    case actions.FETCH_SHARED_ITEM_SUCCESS:
      item = action.response
      return state.set(item.id, itemFromResponse(item))

    case actions.FETCH_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_ITEMS_SUCCESS:
    case popularActions.FETCH_POPULAR_SUCCESS:
    case breakingNewsActions.FETCH_BREAKING_NEWS_SUCCESS:
    case actions.FETCH_CUSTOM_FEED_ITEMS_SUCCESS:
    case actions.FETCH_USER_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_USER_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_USER_ITEMS_SUCCESS:
    case actions.FETCH_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_TAG_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_TAG_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_TAG_ITEMS_SUCCESS:
      return action.response.data.reduce((acc, item) => {
        return acc.set(item.id, itemFromResponse(item))
      }, state)

    case searchActions.FETCH_SEARCH_SUCCESS:
    case searchActions.FETCH_SEARCH_NEXT_SUCCESS:
      return action.response.data.results.items.reduce((acc, item) => {
        return acc.set(item.id, itemFromResponse(item))
      }, state)

    case actions.FETCH_MOST_READ_ITEMS_SUCCESS:
    case actions.FETCH_MOST_VOTED_ITEMS_SUCCESS:
      return [].concat.apply([], action.response.data.map(data => data.items)) // flatten
        .reduce((acc, item) => {
          return acc.set(item.id, itemFromResponse(item))
        }, state)

    case actions.QUEUE_ITEM:
    case actions.QUEUE_CATEGORY_ITEM:
    case userActions.QUEUE_PERSONAL_ITEM:
    case actions.QUEUE_TAG_ITEM:
      item = action.item

      return state.set(item.id, itemFromResponse(item))

    case actions.ITEM_VOTE_SUCCESS:
      return state.update(action.id, item => {
        return item
          .set('plus_votes', action.response.data.data.plus_votes)
          .set('minus_votes', action.response.data.data.minus_votes)
          .set('clicks', action.response.data.data.clicks)
          .set('currentVote', action.voteType)
      })

    case actions.ITEM_REPORT_SUCCESS:
      return state.update(action.id, item => {
        return item
          .set('currentReport', action.reportType)
      })

    case actions.ITEM_CLICK_SUCCESS:
      return state.update(action.id, item => item.set('clicks', item.clicks + 1))

    case actions.FETCH_STATS_FOR_ITEMS_SUCCESS:
      return action.response.data.reduce((acc, item) => {
        return acc
          .setIn([item.id, 'plus_votes'], item.plus_votes)
          .setIn([item.id, 'minus_votes'], item.minus_votes)
          .setIn([item.id, 'clicks'], item.clicks)
      }, state)

    case userActions.FETCH_RECOMMENDED_CATEGORIES_SUCCESS: {
      const items = action.data.map(content => content.items)
        .reduce((acc, item) => {
          return item
        }, Immutable.Map())

      return items.reduce((acc, item) => {
        return acc.set(item.id, itemFromResponse(item))
      }, state)
    }
    default:
      return state
  }
}

function itemState(state = initialItemsState, action) {

  switch (action.type) {
    case actions.FETCH_ITEMS:
    case actions.FETCH_CATEGORY_ITEMS:
    case actions.FETCH_TAG_ITEMS:
      return initialItemsState
        .set('loading', true)
        .set('failedToLoad', false)
        .set('shouldRefresh', false)

    case actions.FETCH_ITEMS_FAILURE:
    case actions.FETCH_CATEGORY_ITEMS_FAILURE:
    case actions.FETCH_TAG_ITEMS_FAILURE:
      return state
        .set('loading', false)
        .set('failedToLoad', true)

    case actions.FETCH_ITEMS_SUCCESS:
    case actions.FETCH_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_TAG_ITEMS_SUCCESS:
    case actions.SET_ITEMS_LANGUAGE: {
      const items = action.response.data
      const itemIds = Immutable.OrderedSet(items.map(item => item.id))

      return state
        .set('loading', false)
        .set('failedToLoad', false)
        .set('itemIds', itemIds)
        .set('itemQueueIds', Immutable.OrderedSet([]))
        .set('itemsQueuedTotal', 0)
        .set('relatedTags', Immutable.OrderedSet(items.reduce((a, item) => {
          return item.tags.reduce((a, tag) => {
            return a.set(tag.slug, a.get(tag.slug) + 1 || 1)
          }, a)
        }, Immutable.Map()).sort((a, b) => a < b).keys()))
        .set('batches', items.reduce((a, item) => {
          return a.set(item.batch.id, Immutable.Map({
            itemIds: Immutable.OrderedSet(),
            size: item.batch.size,
            loading: false,
          }))
        }, state.get('batches')))
    }
    case actions.FETCH_NEXT_ITEMS:
    case actions.FETCH_NEXT_CATEGORY_ITEMS:
    case actions.FETCH_NEXT_TAG_ITEMS:
      return state.set('loadingNext', true)

    case actions.FETCH_NEXT_ITEMS_FAILURE:
    case actions.FETCH_NEXT_CATEGORY_ITEMS_FAILURE:
    case actions.FETCH_NEXT_TAG_ITEMS_FAILURE:
      return state.set('loadingNext', false)

    case actions.FETCH_NEXT_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_NEXT_TAG_ITEMS_SUCCESS: {
      const items = action.response.data
      return state
        .set('loadingNext', false)
        .set('listEnd', items.length < listSize)
        .update('itemIds', itemIds => itemIds.union(Immutable.OrderedSet(items.map(item => item.id))))
        .set('batches', items.reduce((a, item) => {
          return a.set(item.batch.id, Immutable.Map({
            itemIds: Immutable.OrderedSet(),
            size: item.batch.size,
            loading: false,
          }))
        }, state.get('batches')))
    }
    case actions.FETCH_BATCH_ITEMS:
    case actions.FETCH_BATCH_CATEGORY_ITEMS:
      return state.setIn(['batches', action.batch, 'loading'], true)

    case actions.FETCH_BATCH_ITEMS_FAILURE:
    case actions.FETCH_BATCH_CATEGORY_ITEMS_FAILURE:
      return state.setIn(['batches', action.batch, 'loading'], false)

    case actions.FETCH_BATCH_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_CATEGORY_ITEMS_SUCCESS:
    case actions.FETCH_BATCH_TAG_ITEMS_SUCCESS:
      return state
        .setIn(['batches', action.batch, 'itemIds'], Immutable.OrderedSet(action.response.data.map(item => item.id)))
        .setIn(['batches', action.batch, 'loading'], false)

    case actions.FETCH_BATCH_TAG_ITEMS:
      return state.setIn(['batches', action.batch, 'loading'], true)

    case actions.FETCH_BATCH_TAG_ITEMS_FAILURE:
      return state.setIn(['batches', action.batch, 'loading'], false)

    case actions.UNQUEUE_ITEMS:
    case actions.UNQUEUE_CATEGORY_ITEMS:
    case actions.UNQUEUE_TAG_ITEMS:
      return state
        .update('itemIds', itemIds => state.get('itemQueueIds').union(itemIds))
        .set('itemQueueIds', Immutable.OrderedSet([]))

    case actions.QUEUE_ITEM:
    case actions.QUEUE_CATEGORY_ITEM:
    case actions.QUEUE_TAG_ITEM: {
      const itemsQueuedTotal = state.get('itemsQueuedTotal', 0)

      if (itemsQueuedTotal >= listQueueLimit) {
        return state
      }

      return state
        .set('itemsQueuedTotal', itemsQueuedTotal + 1)
        .set('itemQueueIds', state.get('itemQueueIds')
          .reverse()
          .union(Immutable.OrderedSet([action.item.id]))
          .reverse()
        )
        .set('batches', state.get('batches').set(action.item.batch.id, Immutable.Map({
          itemIds: Immutable.OrderedSet(),
          size: action.item.batch.size,
          loading: false,
        })))
    }

    default:
      return state
  }
}

const groupedState = Immutable.Map({
  groups: Immutable.List(),
  loading: false,
  shouldRefresh: false,
})

const groupItems = responseData => {
  return Immutable.List(responseData.map(group => {
    const { minTimestamp, maxTimestamp, items } = group

    return {
      minTimestamp,
      maxTimestamp,
      itemIds: Immutable.OrderedSet(items.map(item => item.id)),
    }
  }))
}

function mostRead(state = groupedState, action) {
  switch (action.type) {
    case actions.FETCH_MOST_READ_ITEMS:
      return state
        .set('loading', true)
        .set('groups', Immutable.List())
        .set('shouldRefresh', false)

    case actions.FETCH_MOST_READ_ITEMS_FAILURE:
      return state.set('loading', false)

    case actions.FETCH_MOST_READ_ITEMS_SUCCESS:
      return state
        .set('loading', false)
        .set('groups', groupItems(action.response.data))

    case userActions.SET_ACTIVE_USER_PROFILE:
    case userActions.SET_ACTIVE_PRESET_PROFILE:
      return state.set('shouldRefresh', true)

    default:
      return state
  }
}

function mostVoted(state = groupedState, action) {
  switch (action.type) {
    case actions.FETCH_MOST_VOTED_ITEMS:
      return state
        .set('loading', true)
        .set('groups', Immutable.List())
        .set('shouldRefresh', false)

    case actions.FETCH_MOST_VOTED_ITEMS_FAILURE:
      return state.set('loading', false)

    case actions.FETCH_MOST_VOTED_ITEMS_SUCCESS:
      return state
        .set('loading', false)
        .set('groups', groupItems(action.response.data))

    case userActions.SET_ACTIVE_USER_PROFILE:
    case userActions.SET_ACTIVE_PRESET_PROFILE:
      return state.set('shouldRefresh', true)

    default:
      return state
  }
}

const userFeedsInitialState = Immutable.Map({
  itemIds: Immutable.OrderedSet(),
  loading: false,
  timestamp: null,
  failedToLoad: false,
  shouldUpdate: false,
})

function userFeeds(state = userFeedsInitialState, action) {
  switch (action.type) {
    case actions.FETCH_CUSTOM_FEED_ITEMS:
      return state
        .set('loading', true)
        .set('failedToLoad', false)
        .set('shouldUpdate', false)

    case actions.FETCH_CUSTOM_FEED_ITEMS_FAILURE:
      return state
        .set('loading', false)
        .set('timestamp', action.timestamp)
        .set('failedToLoad', true)
        .set('shouldUpdate', false)

    case actions.FETCH_CUSTOM_FEED_ITEMS_SUCCESS:
      return state
        .set('loading', false)
        .set('itemIds', Immutable.OrderedSet(action.response.data.map(item => item.id)))
        .set('timestamp', action.timestamp)
        .set('shouldUpdate', false)

    case userActions.SET_ACTIVE_USER_PROFILE:
      return state.set('shouldUpdate', true)

    default:
      return state
  }
}

const itemsLanguageInitialState = Immutable.Map({
  language: 'fi',
})

function itemsLanguage(state = itemsLanguageInitialState, action) {
  switch (action.type) {
    case actions.SET_ITEMS_LANGUAGE:
      return state
        .set('language', action.value)

    default:
      return state
  }
}

export default combineReducers({
  lists,
  activeList,
  shouldInitializeListSegments,
  items,
  mostRead,
  mostVoted,
  tags,
  categories,
  userFeeds,
  itemsLanguage,
})
