import React from 'react'
import { IndexRoute, Route, Router, browserHistory } from 'react-router'
import App from './containers/App'
import { selectIsUserLoggedIn } from './selectors/userSelector'

// 404
import NotFoundView from './views/NotFoundView'

// Search views
import SearchView from './views/Search/SearchView'

// Most used tags views
import AllTagsView from './views/AllTags/AllTagsView'

// News list views
import CategoryView from './views/NewsList/CategoryView'
import FeaturedView from './views/NewsList/FeaturedView'
import PaywalledView from './views/NewsList/PaywalledView'
import NewestView from './views/NewsList/NewestView'
import PersonalView from './views/NewsList/PersonalView'
import MostReadView from './views/NewsList/MostReadView'
import MostVotedView from './views/NewsList/MostVotedView'
import TagView from './views/NewsList/TagView'

// Weather views
import WeatherView from './views/Weather/WeatherView'

// TV views
import TelevisionView from './views/Television/TelevisionView'
import ChannelView from './views/Television/ChannelView'

// Info views
import AboutUsView from './views/Info/AboutUsView'
import FaqView from './views/Info/FaqView'
import SourceHelpView from './views/Info/SourceHelpView'
import FeedbackView from './views/Info/FeedbackView'
import AccountRemovalView from './views/Info/AccountRemovalView'
import SourceApplicationView from './views/Info/SourceApplicationView'
import SourcesView from './views/Info/SourcesView'
//import NewsYearInfo from './views/Info/NewsYearInfo'
import LoginBenefits from './views/Info/LoginBenefits'

// User views
import ForgotPasswordView from './user/ForgotPasswordView'
import NewPasswordView from './user/NewPasswordView'

// User settings views
import SettingsView from './user/settings/SettingsView'
import CategorySettingsView from './user/settings/CategorySettingsView'
import GeneralSettingsView from './user/settings/GeneralSettingsView'
import SourceSettingsView from './user/settings/SourceSettingsView'
import TagSettingsView from './user/settings/TagSettingsView'
import UserFeedsView from './user/settings/UserFeedsView'
import WordFiltersView from './user/settings/WordFiltersView'

// Sports views
import IltalehtiScoreboardView from './views/Sports/IltalehtiScoreboardView'


export default function getRoutes(store) {
  function loggedIn(nextState, replace) {
    const state = store.getState()
    if (!selectIsUserLoggedIn(state)) replace('/') // Used to be /kirjaudu but that page no longer exists
  }

  return (
    <Router history={ browserHistory }>
      <Route path='/' component={ App }>

        <Route path='urheilu/tulospalvelu' component={ IltalehtiScoreboardView } />

        <Route path='saa' component={ WeatherView }>
          <Route path=':location' />
        </Route>

        <Route path='tv' component={ TelevisionView }>
          <Route path='huomenna' />
          <Route path=':channel' component={ ChannelView } />
        </Route>
        <Route path='haku' component={ SearchView } />

        <Route path='tietoa/ukk' component={ FaqView } />
        <Route path='tietoa/mukaan' component={ SourceApplicationView } />
        <Route path='tietoa/lahteet' component={ SourcesView } />
        <Route path='tietoa/lahteille' component={ SourceHelpView } />
        <Route path='palaute' component={ FeedbackView } />
        <Route path='tietoa-palvelusta' component={ AboutUsView } />
        { /*<Route path='tietoa/uutisvuosi-2022' component={ NewsYearInfo } />*/ }
        <Route path='tietoa/kirjautumisen-edut' component={ LoginBenefits } />

        { /** Note! AccountRemovalView is only routed from Play Store.
         * if the path needs to be changed, change it in the play store as well.  */ }
        <Route path='tunnuksen-poistaminen' component={ AccountRemovalView } />

        { /** So IOS/Android app users can set password */ }
        <Route path='unohtunut-salasana' component={ ForgotPasswordView } />
        <Route path='uusi-salasana/:token' component={ NewPasswordView } />

        <Route onEnter={ loggedIn } >
          <Route path='asetukset' component={ SettingsView } >
            <Route path='yleiset' component={ GeneralSettingsView } />
            <Route path='kategoriat' component={ CategorySettingsView } />
            <Route path='lahteet' component={ SourceSettingsView } />
            <Route path='aihetunnisteet' component={ TagSettingsView } />
            <Route path='suodatus' component={ WordFiltersView } />
            <Route path='omat-syotteet' component={ UserFeedsView } />
          </Route>
        </Route>

        <IndexRoute component={ FeaturedView } />
        <Route path='omat' component={ PersonalView } />
        <Route path='uusimmat' component={ NewestView } />
        <Route path='maksulliset' component={ PaywalledView } />
        <Route path='luetuimmat' component={ MostReadView } />
        <Route path='aanestetyimmat' component={ MostVotedView } />
        <Route path='aihetunnisteet' component={ AllTagsView } />

        <Route path='t/:tag' component={ TagView } />
        <Route path=':category' component={ CategoryView } />
        <Route path='*' component={ NotFoundView } />
      </Route>
    </Router>
  )
}
