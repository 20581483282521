import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import Immutable from 'immutable'
import classNames from 'classnames'
import Channel from './Channel'
import Loading from '../../components/util/Loading'
import UnableToLoad from '../../components/messages/UnableToLoad'
import './television.pcss'
import TvProgrammingNoResults from './TvProgrammingNoResults'
import { isTvChannelFavorited } from '../../user/user'
import { TV_FILTER_CHANNEL_FAVORITES } from './filters'
import moment from 'moment-timezone'

export default class TelevisionProgramming extends Component {
  constructor() {
    super()

    this.noFavoritesMessage = (
      <span>
        Ei omia kanavia.<br /><br />
        Voit lisätä kanavan Omat-listaan klikkaamalla kanavan nimen oikealla puolella olevaa <span className='fa fa-star-o' />-ikonia.
      </span>
    )

    this.programming = React.createRef()
  }

  showEmptyChannelsMap = {
    now: false,
    coming: true,
    day: false,
    evening: false,
    night: false,
    movies: false,
    sport: false,
    all: true,
  }

  getTitle() {
    const { controls, isToday, isTomorrow } = this.props

    const name = controls.get('channel') === TV_FILTER_CHANNEL_FAVORITES ? 'Omat suosikit' : 'TV-ohjelmat'

    let suffix
    if (isToday) {
      suffix = 'tänään'
    } else if (isTomorrow) {
      suffix = 'huomenna'
    }

    return (suffix ? name + ' ' + suffix : name)
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.filteredProgramming.filter(channel => this.showChannel(channel)) !== this.props.filteredProgramming.filter(channel => this.showChannel(channel))
    )
  }

  render() {
    const {
      dispatch,
      disabled,
      failedToLoadPrograms,
      loadingPrograms,
      filteredProgramming,
      isToday,
      isFavoritesPage,
      userTvFavorites,
      onDetailsActivation,
      controls,
      onClickLeft,
      onClickRight,
      loggedIn,
      theme,
    } = this.props

    let noContentMessage = null

    // check if we should display "no favorites" message
    if (controls.get('channel') === TV_FILTER_CHANNEL_FAVORITES &&
      userTvFavorites && userTvFavorites.size <= 0) {
      noContentMessage = this.noFavoritesMessage
    }


    const noProgramContent = controls.get('textFilter') !== '' ?
      <TvProgrammingNoResults dispatch={ dispatch } /> : false

    const programming = filteredProgramming ?
      filteredProgramming.filter(channel => this.showChannel(channel)) : Immutable.List()

    const date = controls.get('date')

    return (
      <div className={ classNames('programming', {disabled: disabled}) } ref={ this.programming }>

        <div className='television-title'>
          <h1>{ this.getTitle() }</h1>
          <span className='television-title__date'>{ moment(date ? date : Date.now()).format('dd D.M.') }</span>
        </div>

        { failedToLoadPrograms ?
          <UnableToLoad errorText='Ohjelmatietoja ei saatu ladattua.' />
          :
          <Loading loading={ loadingPrograms }>
            <div className='channels-table'>
              { programming.size > 0 ? programming.map((channel, i) => {
                const channelId = channel.getIn(['channel', 'id'])
                const isFavorite = isTvChannelFavorited(userTvFavorites, channelId)

                return (
                  <Channel
                    dispatch={ dispatch }
                    date={ date }
                    channel={ channel }
                    isFavorite={ isFavorite }
                    key={ channelId }
                    today={ isToday }
                    onDetailsActivation={ onDetailsActivation }
                    onRecenterHorizontally={ this.handleRecenterHorizontally }
                    isFavoritesPage={ isFavoritesPage }
                    channelIndex={ i }
                    userTvFavorites={ userTvFavorites }
                    onClickLeft={ onClickLeft }
                    onClickRight={ onClickRight }
                    loggedIn={ loggedIn }
                    noProgramContent={ noProgramContent }
                    theme={ theme }
                  />
                )
              }) : (
                <TvProgrammingNoResults dispatch={ dispatch }>
                  { noContentMessage }
                </TvProgrammingNoResults>
              ) }
            </div>
          </Loading>
        }
      </div>
    )
  }

  showChannel = (channel) => {

    if (!channel) return false

    const { controls } = this.props

    if (channel.get('programs').size === 0) {

      if (controls.get('filterText') !== '') return false

      const map = this.showEmptyChannelsMap
      return !(map[controls.get('program')] === false || map[controls.get('programType')] === false)
    }

    return true
  }

  handleRecenterHorizontally = (centerLeft, centerTop) => {
    const container = this.programming.current
    container.scrollLeft = centerLeft
    container.scrollTop = centerTop // fix an issue with the last elements that end up hidden if revealed at the bottom
  }
}

TelevisionProgramming.propTypes = exact({
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  failedToLoadPrograms: PropTypes.bool.isRequired,
  loadingPrograms: PropTypes.bool.isRequired,
  filteredProgramming: ImmutablePropTypes.list.isRequired,
  isToday: PropTypes.bool.isRequired,
  isFavoritesPage: PropTypes.bool.isRequired,
  userTvFavorites: ImmutablePropTypes.orderedSet.isRequired,
  onDetailsActivation: PropTypes.func.isRequired,
  controls: ImmutablePropTypes.map.isRequired,
  onClickLeft: PropTypes.func.isRequired,
  onClickRight: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isTomorrow: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
})
