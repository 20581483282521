import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'
import Immutable from 'immutable'
import Loading from '../components/util/Loading'
import Item from '../components/item/Item'
import MoreContent from '../components/widgets/MoreContent'
import { itemIdsToItems } from '../components/item/itemsTransformers'
import { fetchMostRead } from '../components/item/itemsActions'
import { setStatus } from '../status/statusActions'
import SidebarMenu from '../components/layout/SidebarMenu'
import GeneralSidebar from '../components/layout/GeneralSidebar'
import '../styles/not-found.pcss'
import MainContent from '../components/wrappers/MainContent'

@provideHooks({
  fetch: ({ dispatch }) => {
    return dispatch(setStatus(404))
  },
})
class NotFoundView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    dispatch: PropTypes.func.isRequired,
    items: ImmutablePropTypes.list.isRequired,
    loading: PropTypes.bool.isRequired,
    timestamp: PropTypes.number.isRequired,
    loggedIn: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchMostRead())
  }

  render() {
    const {
      dispatch,
      items,
      loading,
      timestamp,
      loggedIn,
    } = this.props

    return (
      <div className='container'>
        <Helmet title='Sivua ei löydy' />

        <div className='main-container'>
          <SidebarMenu />

          <div id='sticky-ad-bottom-boundary' >
            <MainContent>
              <div className='not-found-container'>
                <div className='not-found-icon fa fa-chain-broken' />
                <div>
                  <h1>Hups, minne eksyit?</h1>

                  <p>
                    Täältä ei löydy uutisia eikä sisältöä, mutta ei hätää!
                    Valitse valikosta aihe, hae uutisia tai tutki allaolevista uutisista
                    jotain kiinnostavaa ja jatka uusimpien uutisien seuraamista:
                  </p>
                </div>
              </div>

              <section className='items-list'>
                <h3>Luetuimmat viimeisen tunnin ajalta</h3>

                <Loading loading={ loading }>
                  { items.map(item => (
                    <Item
                      key={ item.get('id') }
                      item={ item }
                      timestamp={ timestamp }
                      dispatch={ dispatch }
                      loggedIn={ loggedIn }
                    />
                  )) }
                </Loading>
              </section>
            </MainContent>

            <MoreContent title='Lisää uutisia odottaa' />
          </div>

          <div className='sidebar-container'>
            <GeneralSidebar />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => {
  const { mostRead } = state.items

  let items = Immutable.List()

  const group = mostRead.get('groups')
    .find(groupCandidate => {
      return moment
        .duration(moment.unix(groupCandidate.maxTimestamp).diff(moment.unix(groupCandidate.minTimestamp)))
        .asHours() === 1
    })

  if (group) {
    items = itemIdsToItems(state, group.itemIds.toList())
  }


  return {
    items,
    loading: mostRead.get('loading') || items.isEmpty(),
    timestamp: state.time.get('timestamp'),
  }
})(NotFoundView)
