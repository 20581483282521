import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { fetchMostRead } from '../../components/item/itemsActions'
import GroupedItems from './GroupedItems'
import groupedConnect from '../../lib/groupedConnect'
import { PostContentAd } from '../../ad/Ad'
import { fetchPopular } from '../../components/item/popularActions'

const titles = {
  1: '10 luetuinta viimeisen tunnin ajalta',
  2: '10 luetuinta viimeisen 3 tunnin ajalta',
  3: '10 luetuinta viimeisen 6 tunnin ajalta',
  6: '10 luetuinta viimeisen 12 tunnin ajalta',
  12: '10 luetuinta viimeisen 24 tunnin ajalta',
  24: '10 luetuinta lopuista',
}

@provideHooks({
  fetch: ({ dispatch }) => {
    return Promise.all([
      dispatch(fetchMostRead()),
      dispatch(fetchPopular(300)),
    ])
  },
})
export class MostReadView extends Component {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    dispatch: PropTypes.func.isRequired,
    shouldRefresh: PropTypes.bool.isRequired,
  }

  render() {
    return (
      <Fragment>
        <GroupedItems
          { ...this.props }
          headingTitles={ titles }
          title='Luetuimmat | Uutiset'
          meta={ [
            {name: 'description', content: 'Ampparit.com kertoo, mitkä ovat Suomen suosituimpia uutisia juuri nyt. Näet Luetuimmat-listalta yhdellä silmäyksellä, mitä uutisia Suomi lukee. Se kattavin uutistarjonta \u2013 Ampparit.com'},
            {property: 'og:title', content: 'Luetuimmat \u2013 Ampparit.com'},
            {property: 'og:description', content: 'Ampparit.com kertoo, mitkä ovat Suomen suosituimpia uutisia juuri nyt. Näet Luetuimmat-listalta yhdellä silmäyksellä, mitä uutisia Suomi lukee. Se kattavin uutistarjonta \u2013 Ampparit.com'},
          ] }
        />
        <PostContentAd />
      </Fragment>
    )
  }
  componentDidUpdate(prevProps) {
    const { dispatch } = this.props

    if (this.props.shouldRefresh && !prevProps.shouldRefresh) {
      dispatch(fetchMostRead())
    }
  }
}

export default connect(state => groupedConnect(state, state.items.mostRead, 'Luetuimmat', '/luetuimmat'))(MostReadView)
