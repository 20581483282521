import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Link } from 'react-router'
import { resetFilters } from './tvActions'

export default class TvProgrammingNoResults extends Component {
  render() {
    const { children } = this.props

    return (
      <div className='tv-programming-no-results'>
        { children ? children : (
          <span>
            Valitsemillasi suodatuksilla ei löytynyt ohjelmaa.<br /><br />
            Muokkaa suodatuksia tai <Link to='/tv' onClick={ this.handleResetFiltersClick }>tyhjennä suodatukset</Link>.
          </span>
        ) }
      </div>
    )
  }

  handleResetFiltersClick = (e) => {
    e.preventDefault()
    this.props.dispatch(resetFilters(true))
  }
}

TvProgrammingNoResults.propTypes = exact({
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
})
