import Immutable from 'immutable'

import { REMOVE_NOTIFICATION, GENERIC_NOTIFICATION } from './notificationActions'
import * as itemsActions from '../../components/item/itemsActions'
import * as searchActions from '../../views/Search/searchActions'
import * as userActions from '../../user/userActions'
import * as feedbackActions from '../../views/Info/feedbackActions'
import * as locationActions from '../../components/location/locationActions'

const MIN_ACTIVE_SOURCES = 3

const initialState = Immutable.Map({})

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {

    case GENERIC_NOTIFICATION: {
      const notificationId = action.id || action.type
      if (state.has(notificationId)) return state

      return state.set(action.type, {
        message: action.message,
        level: action.level,
      })
    }
    case locationActions.GET_GEOLOCATION_FAILURE: {
      if (state.has(action.type)) return state

      const error = action.error

      let message
      switch (error.code) {
        case error.PERMISSION_DENIED:
          message = 'Sijainnin haku on estetty selaimen asetuksista.'
          break
        default:
          message = 'Sijainnin haku ei onnistunut.'
      }

      return state.set(action.type, {
        message: message,
        level: 'error',
      })
    }
    case userActions.ADD_WEATHER_LOCATION_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: action.tempFull ? 'Lisääminen ei onnistunut, väliaikaisen profiiliin paikkakuntalista on täynnä.' : 'Paikkakunnan lisääminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.SET_WEATHER_LOCATIONS_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Sijaintien tallentaminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.ADD_USER_WORD_FILTER_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Suodattimen lisääminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.ADD_USER_WORD_FILTER_MAX:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Suodattimia voi olla enintään 40. Poista suodattimia lisätäksesi uuden',
        level: 'error',
      })

    case userActions.SET_VOTE_FILTER_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Suodatinarvon tallentaminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.SET_VOTE_FILTER_SUCCESS:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Suodattimen tallentaminen onnistui.',
        level: 'success',
      })

    case userActions.ADD_USER_SAVED_SEARCH_SUCCESS:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: `Haun "${action.searchTerm}" tallennus onnistui.`,
        level: 'success',
      })

    case userActions.ADD_USER_SAVED_SEARCH_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: `Haun "${action.searchTerm}" tallentaminen ei onnistunut. Yritä uudelleen.`,
        level: 'error',
      })

    case userActions.ADD_USER_SAVED_SEARCH_MAX:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Tallennettuja hakuja voi olla enintään 5',
        level: 'error',
      })

    case userActions.REMOVE_USER_SAVED_SEARCH_SUCCESS:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: `Haku "${action.searchTerm}" poistettu.`,
        level: 'success',
      })

    case userActions.REMOVE_USER_SAVED_SEARCH_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: `Hakua "${action.searchTerm}" ei voitu poistaa. Yritä uudelleen.`,
        level: 'error',
      })

    case userActions.ADD_CUSTOM_FEED_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Lähteen lisääminen epäonnistui. Tarkasta lähteen osoite.',
        level: 'error',
      })

    case userActions.ADD_CUSTOM_FEED_SUCCESS:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Oman syötteen lisäys onnistui. Huomioithan, että syöte ilmestyy uutislistallesi pienellä viiveellä.',
        level: 'success',
      })

    case userActions.REMOVE_USER_WORD_FILTER_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Suodattimen poistaminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.SET_TV_CHANNEL_FAVORITES_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Omien kanavien tallennus ei onnistunut.',
        level: 'error',
      })

    case userActions.FETCH_TV_CHANNEL_FAVORITES_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Omia kanavia ei saatu ladattua.',
        level: 'error',
      })

    case itemsActions.FETCH_SHARED_ITEM_FAILURE:
      return state.set(action.type, {
        message: 'Jaettua uutista ei löytynyt. Jakolinkki on virheellinen tai vanhentunut.',
        level: 'error',
      })

    case itemsActions.FETCH_ITEMS_FAILURE:
    case itemsActions.FETCH_MOST_READ_ITEMS_FAILURE:
    case itemsActions.FETCH_MOST_VOTED_ITEMS_FAILURE:
    case searchActions.FETCH_SEARCH_FAILURE:
    case searchActions.FETCH_SEARCH_NEXT_FAILURE:
      if (state.has('FETCH_ITEMS_FAILURE')) return state
      return state.set('FETCH_ITEMS_FAILURE', {
        message: 'Uutislistaa ei saatu ladattua. Yritä uudelleen.',
        level: 'error',
      })

    case itemsActions.FETCH_NEXT_ITEMS_FAILURE:
      if (state.has('FETCH_ITEMS_FAILURE')) return state
      return state.set('FETCH_ITEMS_FAILURE', {
        message: 'Lisää uutisia ei saatu ladattua. Yritä uudelleen.',
        level: 'error',
      })

    case itemsActions.ITEM_VOTE_FAILURE:
      if (state.has('VOTE_ITEM_FAILURE')) return state
      return state.set('VOTE_ITEM_FAILURE', {
        message: 'Äänen rekisteröinti ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.BLACKLIST_CATEGORY_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Kategorian piilottaminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.UNBLACKLIST_CATEGORY_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Piilottamisen poistaminen epäonnistui. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.WHITELIST_CATEGORY_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Kategorian seuraaminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.UNWHITELIST_CATEGORY_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Seuraamisen poistaminen epäonnistui. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.CHANGE_THEME_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Teeman vaihtaminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.CHANGE_LAYOUT_WIDTH_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Sivupohjan leveyden muuttaminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.UNBLACKLIST_SOURCE_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Lähteen piilottaminen poistaminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case userActions.BLACKLIST_SOURCE_FAILURE:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Lähteen piilottaminen ei onnistunut. Yritä uudelleen.',
        level: 'error',
      })

    case REMOVE_NOTIFICATION:
      return state.delete(action.id)

    case userActions.LOGIN_USER_SUCCESS:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Olet kirjautunut sisään',
        level: 'success',
      })

      /*
    case userActions.LOGOUT_USER_SUCCESS:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Olet kirjautunut ulos',
        level: 'success',
      })
    */

    case feedbackActions.SEND_FEEDBACK_FAILURE:
      if (state.has('CONNECTION_OR_SYSTEM_FAILURE')) return state
      return state.set('CONNECTION_OR_SYSTEM_FAILURE', {
        message: 'Tapahtui järjestelmävirhe.',
        level: 'error',
      })

    case userActions.SOURCES_MIN_LIMIT:
      if (state.has(action.type)) return state
      return state.set(action.type, {
        message: 'Lähteiden minimimäärä on ' + MIN_ACTIVE_SOURCES + '.',
        level: 'error',
      })

    case userActions.RESET_TEMPORARY_PROFILE:
      if (state.has(action.type)) return state
      if (!action.notify) return state
      return state.set(action.type, {
        message: 'Väliaikanen profiili tyhjennetty.',
        level: 'success',
      })


    default:
      return state
  }
}
