import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class TvTextFilter extends Component {
  static propTypes = exact({
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  })


  constructor() {
    super()

    this.state = {
      inputValue: '',
    }
  }

  componentDidMount() {
    this.setState({
      inputValue: this.props.text,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      if (this.state.inputValue !== prevProps.text) {
        this.setState({
          inputValue: this.props.text,
        })
      }
    }
  }

  render() {
    return (
      <div className='tv-text-filter'>
        <span className='tv-text-filter__search-icon fa fa-search' aria-hidden='true' />
        <input
          className='tv-text-filter__filter-input'
          type='text'
          maxLength='50'
          placeholder='Etsi TV-ohjelmista...'
          aria-label='Etsi TV-ohjelmista'
          value={ this.state.inputValue }
          onChange={ this.handleChange }
          onKeyDown={ this.handleKeyDown }
          onKeyUp={ this.handleKeyUp }
        />
        { this.state.inputValue &&
          <button onClick={ this.handleClear } className='clear-icon fa fa-close' />
        }
      </div>
    )
  }

  handleClear = () => {
    this.handleInputValue('')
  }

  handleChange = (e) => {
    this.handleInput(e)
  }

  handleKeyDown = (e) => {
    this.handleInput(e)
  }

  handleKeyUp = (e) => {
    this.handleInput(e)
  }

  handleInput(e) {
    this.handleInputValue(e.target.value)
  }

  handleInputValue = (value) => {
    if (this.state.inputValue !== value) {
      this.setState({
        inputValue: value,
      })

      this.props.onChange(value)
    }
  }

}
