import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import moment from 'moment-timezone'
import Dropdown from 'react-dropdown'
import Immutable from 'immutable'
import TimeSlotGroup from './TimeSlotGroup'
import {
  getTVFiltersToday,
  TV_FILTER_CHANNEL_ALL,
  TV_FILTER_CHANNEL_FAVORITES,
  TV_FILTER_CHANNEL_FREE,
  TV_FILTER_CHANNEL_PAID,
  TV_FILTER_PROGRAM_TIME_COMING,
  TV_FILTER_PROGRAM_TIME_ALL,
  TV_FILTER_PROGRAM_TIME_NOW,
  TV_FILTER_PROGRAM_TYPE_ALL,
  TV_FILTER_PROGRAM_TYPE_MOVIES,
  TV_FILTER_PROGRAM_TYPE_SPORT,
} from './filters'
import TvTextFilter from './TvTextFilter'
import './television-controls.pcss'
import SideSelectors from '../../components/util/SideSelectors'


export default class TvControls extends PureComponent {
  constructor(props) {
    super(props)
    const dateOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(days => {
      const date = moment().add(days, 'days').startOf('day')
      const label = date.calendar(null, {
        sameDay: '[Tänään] dd D.M.',
        nextDay: '[Huomenna] dd D.M.',
        nextWeek: 'dd D.M.',
        sameElse: 'dd D.M.',
      })
      return {
        value: date.format('YYYY-MM-DD'),
        label: label[0].toUpperCase() + label.slice(1),
      }
    })
    this.dateOptions = Immutable.List(dateOptions)
  }

  channelOptions = Immutable.List([
    {value: TV_FILTER_CHANNEL_FAVORITES, label: 'Omat suosikit'},
    {value: TV_FILTER_CHANNEL_FREE, label: 'Peruskanavat'},
    {value: TV_FILTER_CHANNEL_PAID, label: 'Maksukanavat'},
    {value: TV_FILTER_CHANNEL_ALL, label: 'Kaikki kanavat'},
  ])

  programTypeOptions = Immutable.List([
    {value: TV_FILTER_PROGRAM_TYPE_ALL, label: 'Kaikki ohjelmat'},
    {value: TV_FILTER_PROGRAM_TYPE_MOVIES, label: 'Elokuvat'},
    {value: TV_FILTER_PROGRAM_TYPE_SPORT, label: 'Urheilu'},
  ])

  render() {
    const {
      controls,
      singleChannelPage,
    } = this.props

    const dateOption = controls.get('date') ? controls.get('date') : getTVFiltersToday()

    return (
      <div className='television-controls'>
        <div className='television-controls__dropdowns'>
          { !singleChannelPage &&
          <div className='television-controls__dropdown'>
            <SideSelectors
              options={ this.channelOptions.toJS() }
              onChange={ this.handleChannelFilterChange }
              value={ this.channelOptions.find(opt => opt.value === controls.get('channel')).label }
            >
              <Dropdown
                options={ this.channelOptions.toJS() }
                onChange={ this.handleChannelFilterChange }
                value={ this.channelOptions.find(opt => opt.value === controls.get('channel')).label }
              />
            </SideSelectors>
          </div>
          }
          <div className='television-controls__dropdown'>
            <SideSelectors
              options={ this.dateOptions.toJS() }
              onChange={ this.handleDateChange }
              value={ this.dateOptions.find(opt => opt.value === dateOption).label }
            >
              <Dropdown
                options={ this.dateOptions.toJS() }
                onChange={ this.handleDateChange }
                value={ this.dateOptions.find(opt => opt.value === dateOption).label }
              />
            </SideSelectors>
          </div>
          <div className='television-controls__dropdown'>
            <SideSelectors
              options={ this.programTypeOptions.toJS() }
              onChange={ this.handleProgramTypeFilterChange }
              value={ this.programTypeOptions.find(opt => opt.value === controls.get('programType')).label }
            >
              <Dropdown
                options={ this.programTypeOptions.toJS() }
                onChange={ this.handleProgramTypeFilterChange }
                value={ this.programTypeOptions.find(opt => opt.value === controls.get('programType')).label }
              />
            </SideSelectors>
          </div>
        </div>

        <div className='television-controls__program-filters'>
          <TimeSlotGroup
            onHandleProgramTimeFilterChange={ this.handleProgramTimeFilterChange }
            activeFilter={ controls.get('program') }
          />
          <TvTextFilter
            text={ controls.get('textFilter') }
            onChange={ this.handleTvFilterInputChange }
          />
        </div>
      </div>
    )
  }

  handleProgramTimeFilterChange = (value) => {
    const {
      controls,
      setDateFilter,
      setProgramTimeFilter,
    } = this.props

    // 'coming' and 'now' programs are only for today date, so lets set the date accordingly
    if (controls.get('date') !== getTVFiltersToday() &&
      (value === TV_FILTER_PROGRAM_TIME_NOW || value === TV_FILTER_PROGRAM_TIME_COMING)) {
      setDateFilter(getTVFiltersToday(), false)
    }

    setProgramTimeFilter(value)
  }

  handleProgramTypeFilterChange = ({value}) => {
    this.props.setProgramTypeFilter(value)
  }

  handleChannelFilterChange = ({value}) => {
    this.props.setChannelFilter(value)
  }

  handleDateChange = ({value}) => {

    const { controls, setDateFilter, setProgramTimeFilter} = this.props

    const programTimeFilter = controls.get('program')

    // 'coming' and 'now' programs are only for today date, so lets switch to 'day' program
    // if user selects any other date
    if (value !== getTVFiltersToday() && (programTimeFilter === TV_FILTER_PROGRAM_TIME_NOW || programTimeFilter === TV_FILTER_PROGRAM_TIME_COMING)) {
      setProgramTimeFilter(TV_FILTER_PROGRAM_TIME_ALL, false)
    }

    setDateFilter(value)
  }

  handleTvFilterInputChange = (value) => {
    this.props.setTextFilter(value)
  }
}

TvControls.propTypes = exact({
  setTextFilter: PropTypes.func.isRequired,
  setChannelFilter: PropTypes.func.isRequired,
  setProgramTimeFilter: PropTypes.func.isRequired,
  setProgramTypeFilter: PropTypes.func.isRequired,
  setDateFilter: PropTypes.func.isRequired,
  singleChannelPage: PropTypes.bool.isRequired,
  controls: ImmutablePropTypes.map.isRequired,
})
