import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { Helmet } from 'react-helmet'
import Button from '../../components/ui/Button'
import Toggle from 'react-toggle'
import Select from 'react-select'
import { addFeed, fetchUserProfile, removeFeed, setShowUserFeedsBoxInSidebar, updateFeed } from '../userActions'
import { selectCategories } from '../../selectors/categoriesSelector'
import { validateURL } from '../validate'
import { selectUserCustomFeeds } from '../../selectors/userSelector'
import '../../styles/settings.pcss'
import './userFeedsView.pcss'


@provideHooks({
  fetch: ({ dispatch }) => {
    return Promise.all([
      dispatch(fetchUserProfile(5)),
    ])
  },
})

@connect((state) => {
  return {
    categories: selectCategories(state),
    userFeeds: selectUserCustomFeeds(state),
    userFeedsBoxInSidebar: state.user.get('showUserFeedsBoxInSidebar'),
  }
})

export default class UserFeedsView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: '',
    }

    this.inputRef = React.createRef()
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    userFeeds: ImmutablePropTypes.orderedSetOf(ImmutablePropTypes.record).isRequired,
    categories: ImmutablePropTypes.map.isRequired,
    userFeedsBoxInSidebar: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  render() {
    const {
      errorMessage,
    } = this.state

    const {
      userFeeds,
      categories,
      userFeedsBoxInSidebar,
    } = this.props


    // For some reason, API only allows top and 2nd level categories for feeds
    const categoryOptions = categories.toList()
      .filter(category => category.get('treeLevel') < 3)
      .sort((a, b) => { return (a.name > b.name) - (a.name < b.name) })
      .map(category => {
        return { value: category.id, label: category.name }
      }).toArray()

    return (
      <div>
        <Helmet title='Omat syötteet' />

        <section className='content'>

          <div className='feeds-box-display'>
            <h3>Näytä omia syötteitä sivun oikeassa sivupalkissa</h3>
            <div>
              <label htmlFor='show-sidebar-feeds'>Syötteet näytetään sivupalkissa</label>
              <Toggle
                checked={ userFeedsBoxInSidebar }
                onChange={ this.handleShowUserFeedsInSidebarToggle }
                id='show-sidebar-feeds'
              />
            </div>
          </div>

          <hr />

          <div>
            <h3>Omat syötteet</h3>
            <p>
              Omat syötteet näkyvät uusimpien uutisten joukossa, sivupalkissa ja Omat-sivulla.
              Voit halutessasi valita syötteelle kategorian, jossa syöte lisäksi näkyy.
            </p>

            <div className='settings-input-error'>{ errorMessage }</div>

            <div className='add-feed'>
              <input
                aria-label='Lisättävän RSS-syötteen osoite'
                className='add-feed__input'
                ref={ this.inputRef }
                placeholder='Lisää uusi syöte'
                type='url'
              />
              <Button className='add-feed__button' type='button' text='Lisää' onClick={ this.handleAddFeed } />
            </div>

            <div className='add-feed-description'>
              RSS-syöte, esim. http://feeds.bbci.co.uk/news/rss.xml
            </div>

            { userFeeds.toArray().map(feed => (
              <Feed
                key={ feed.id }
                feed={ feed }
                categoryOptions={ categoryOptions }
                dispatch={ this.props.dispatch }
              />
            )) }
          </div>
        </section>
      </div>
    )
  }

  handleShowUserFeedsInSidebarToggle = () => {
    const { userFeedsBoxInSidebar, dispatch } = this.props
    dispatch(setShowUserFeedsBoxInSidebar(!userFeedsBoxInSidebar))
  }

  handleAddFeed = () => {
    const { dispatch } = this.props
    const url = this.inputRef.current.value.trim()

    if (!validateURL(url)) {
      this.setState({ errorMessage: 'Tarkista osoitteen muoto.' })
      return
    }

    this.setState({ errorMessage: '' })
    dispatch(addFeed(url))
  }
}


const selectStyles = {
  control: styles => ({ ...styles, color: '#000000' }),
  option: styles => ({ ...styles, color: '#000000' }),
}

const Feed = ({ dispatch, feed, categoryOptions }) => {
  const handleRemove = () => {
    dispatch(removeFeed(feed.id))
  }

  function handleCategoryChange(selection) {
    const categoryId = selection ? selection.value : 0
    dispatch(updateFeed(feed.id, categoryId))
  }

  const selectedOption = categoryOptions.find(option => option.value === feed.categoryId)

  return (
    <div className='user-feed'>
      <div className='user-feed__data'>
        <div className='user-feed__url'>{ feed.url }</div>
        <div className='user-feed__category'>
          <Select
            aria-label={ 'Valitse kategoria syötteelle ' + (feed.name || feed.url) }
            isClearable={ true }
            placeholder='Valitse kategoria...'
            value={ selectedOption }
            options={ categoryOptions }
            onChange={ handleCategoryChange }
            styles={ selectStyles }
          />
        </div>
      </div>
      <button
        type='button'
        className='user-feed__remove fa fa-times'
        onClick={ handleRemove }
        title='Poista syöte'
        aria-label='Poista syöte'
      />
    </div>
  )
}

Feed.propTypes = PropTypes.exact({
  feed: ImmutablePropTypes.record.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
})
