import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ImmutablePropTypes from 'react-immutable-proptypes'

export default class ChannelArrows extends PureComponent {

  static propTypes = exact({
    channelIndex: PropTypes.number.isRequired,
    onClickLeft: PropTypes.func.isRequired,
    onClickRight: PropTypes.func.isRequired,
    userTvFavorites: ImmutablePropTypes.orderedSet.isRequired,
  })

  render() {
    const { channelIndex, userTvFavorites, onClickLeft, onClickRight } = this.props
    return (
      <div className='channel-arrow'>
        <button
          type='button'
          value={ channelIndex }
          onClick={ onClickLeft }
          disabled={ channelIndex === 0 }
          className='channel-arrow channel-arrow__left'
        >
          <div className='fa fa-angle-left' />
        </button>
        <button
          type='button'
          value={ channelIndex }
          onClick={ onClickRight }
          disabled={ channelIndex === (userTvFavorites.size - 1) }
          className='channel-arrow channel-arrow__right'
        >
          <div className='fa fa-angle-right' />
        </button>
      </div>
    )
  }
}
